export type DictionaryType = typeof enValues

/**
 * English translations for application text
 *
 * @notExported
 */
const enValues = {
  notifications: 'Notifications',
  accountMenu: 'Account menu',
  required: 'required',
  noMobileView: 'This view is not available in mobile devices, please use desktop device!',
  dashboard: 'Dashboard',
  submit: 'Submit',
  send: 'Send',
  sent: 'Sent',
  createdAt: 'Created at',
  forgotPassword: 'Forgot Password?',
  'forgotPassword.email': 'Email',
  emailIsNotValid: 'Email address is not valid!',
  'name-used-error': 'Name already in use!',
  'company-id-used-error': 'Company identifier already in use!',
  'same-password-error': 'The new password cannot be the same as the old one!',
  'wrong-password-error': 'Wrong current password, please try again!',
  loginheader: 'Welcome',
  login: 'Login',
  logout: 'Logout',
  back: 'Go back',
  email: 'Email',
  promoted: 'Promoted',
  'login.password': 'Password',
  'login.email': 'Email',
  'login.remember': 'Keep logged in',
  'azureLogin.button': 'Azure AD login',
  'azureLogin.tenantError': 'Organization does not have Azure AD login permissions!',
  'azureLogin.clientError': 'Application missing login permissions!',
  'azureLogin.claimsError': 'Incorrect claims for login!',
  'azureLogin.error': 'Error in Azure AD login!',
  or: 'OR',
  settings: 'Account settings',
  profile: 'Profile',
  organization: 'Organization',
  myCV: 'My CV',
  resources: 'Employees',
  noRights: 'User does not have permissions to access resource.',
  admin: 'Admin',
  allocation: 'Allocations',
  allocations: 'allocations',
  error: 'Login failed, please check your credentials',
  errorfield: 'Please enter email address and password',
  errorfieldreset: 'Please enter the email address attached to your account and re-select the button',
  errorrbackreset: 'Password changing failed. Please try again.',
  resetpassword: 'Forgot your password?',
  contactSales: 'Contact sales',
  officeAddress: 'Office address',
  'resetPassword.header': 'Reset password',
  'resetPassword.password': 'New password',
  'resetPassword.repeatPassword': 'Repeat new password',
  startDateEndDateError: 'Dates are invalid.',
  numberTypeError: 'Input should be a number.',
  allocationPercentError: 'Percent should be between 1-100',
  select: 'Select',
  projectsWith: 'projects with',
  projectWith: 'project with',
  hide: 'Hide',
  show: 'Show',
  'resetasked-ok': 'OK',
  'resetasked-header': 'Check your email',
  'resetasked-text':
    'Email instructions how to restore the password was sent to given account if the given email address was found from the system.',
  printpreview: 'Select or add custom layout to organize CV content for printing and download a printable CV',
  frontpage: 'Front Page',
  help: 'Help',
  team: 'Team',
  view: 'View',
  accountSettings: 'Account Settings',
  acceptFollowingTerms: 'The following terms require acceptance',
  accountActivatedMessage:
    'Thank you for activating your account. You will now be redirected to the login page where you can log in with your email address and the password you just set.',
  close: 'Close',
  company: 'Company',
  accessDenied: 'Oops! Just empty space here.',
  accessDeniedBody: 'The page you are looking for is beyond our reach.',
  showMore: 'Show more',
  showLess: 'Show less',
  emailError: 'Email already in use!',
  showAll: 'all',
  showAllResults: 'Show all results',
  contactInformation: 'Contact information',
  'contactInformation.addTitle': 'Contact Information',
  'contactInformation.editTitle': 'Contact Information',
  'contactInformation.telephone': 'Telephone',
  notificationSettings: 'Notification Settings',
  'notificationSettings.editTitle': 'Notification Settings',
  'notificationSettings.switch': 'Receive emails from Caleō',
  'notificationSettings.new-assignments': 'Receive emails for new assignments',
  'notificationSettings.weekly-summary': 'Receive weekly summary of assignments',
  'notificationSettings.monthly-summary': 'Receive monthly summary of assignments',
  'notificationSettings.chat-messages': 'Receive emails for new chat messages',
  'notificationSettings.network-notifications': 'Receive emails for network notifications',
  apply: 'Apply',
  ownOrganization: 'Own organization',
  yes: 'Yes',
  no: 'No',
  unsubscribed: 'You have been unsubscribed!',
  fileUploaded: 'File uploaded!',
  notAvailable: 'Not available',
  skillWarning: 'Add {{type}} one by one, do not add multiple {{type}} at the same instance.',
  skillLower: 'skills',
  roleLower: 'roles',
  industryLower: 'industries',
  toggleAll: 'All columns',
  hideColumns: 'Edit columns',
  name: 'Name',
  skillsCount: 'Skills count',
  rolesCount: 'Roles count',
  industriesCount: 'Industries count',
  workExperienceYears: 'Work experience years',
  workExperienceMonths: 'Work experience months',
  'navigation.unsubscribe': 'Unsubscribed',
  'placeholder.organization': 'Add Organization',
  'placeholder.skill': 'Add Skill',
  'placeholder.industry': 'Add Industry',
  'placeholder.role': 'Add Role',
  'placeholder.person': 'Add Person',
  'translationImport.missing': 'No english text found.',
  'translationImport.description': 'The added text is displayed when the item is viewed in english.',
  'translationImport.addOld': 'Copy finnish text',
  'translationImport.addNew': 'Add text',
  'translationImport.dataMissing': 'No translation data! Please contact support!',
  'translationImport.noTranslation': 'Please fill the initial language!',
  'translationImport.helperText': 'Added information is displayed in english.',
  'languages.fi': 'Finnish',
  'languages.en': 'English',
  'languages.sv': 'Swedish',
  'controls.selectdialoglanguage': 'Select input language',
  'controls.selectgloballanguage': 'Select user interface language',
  'controls.undo': 'Undo',
  'controls.undoCopy': 'Field values were copied from the previous language.',
  'controls.save-copy': 'Missing translations were copied from the active language.',
  'controls.noOptionsText': 'No options',
  'password.resetPassword': 'Reset Password',
  'password.reset': 'Reset',
  'password.newPassword': 'New Password',
  'password.verification': 'Verification',
  'password.password': 'Password',
  'password.repeatPassword': 'Repeat Password',
  'password.error': 'Password change failed',
  'password.notmatch': 'Passwords do not match, please try again!',
  'password.matchError': 'The new password cannot be the same as the old one!',
  'password.empty': 'Please enter password',
  'password.passwordchange': 'password changing',
  'password.newpassword': 'New password',
  'password.newpasswordagain': 'New password again',
  'password.changepassword': 'Change password',
  'password.activateAccount': 'Account activation',
  'password.activateAccount.invalidToken': `Activation link has been used. If you have forgotten your password, please click "forgot password"`,
  'password.activate': 'Activate',
  'password.activated': 'Password was changed succesfully! You will be redirected to the login page in a few seconds.',
  'password.alreadyActivated': 'Account already activated',
  'password.notFound': 'Account does not exist',
  'password.server': 'Server error',
  'password.tooShort': 'Password must be at least 8 characters long.',
  'password.tooLong': 'Password maximum length is {{passwordMaxLength}}.',
  'changePassword.header': 'Change password',
  'changePassword.currentPassword': 'Current password',
  'changePassword.newPassword': 'New password',
  'changePassword.confirmPassword': 'Confirm password',
  'navigation.dashboard': 'Dashboard',
  'navigation.404': '404',
  'navigation.403': '403',
  'navigation.submit': 'Submit',
  'navigation.emailIsNotValid': 'Email address is not valid!',
  'navigation.loginheader': 'Welcome',
  'navigation.login': 'Login',
  'navigation.logout': 'Logout',
  'navigation.email': 'Email',
  'navigation.password': 'Password',
  'navigation.profile': 'My Profile',
  'navigation.assignments': 'Assignments',
  'navigation.myCV': 'My CV',
  'navigation.employees': 'Search',
  'navigation.admin': 'Admin',
  'navigation.scout': 'Scout',
  'navigation.scoutPlus': 'Scout-Plus',
  'navigation.scoutFAQ': 'Scout FAQ',
  'navigation.arvoviisari': 'Arvoviisari',
  'navigation.allocation': 'Allocations',
  'navigation.print': 'CV Export',
  'navigation.statistics': 'Reports',
  'navigation.scoutStatistics': 'Scout',
  'navigation.scoutPlusStatistics': 'Scout Plus',
  'navigation.certificateStatistics': 'Certificates',
  'navigation.error': 'Login failed, please check your credentials',
  'navigation.errorfield': 'Please enter email address and password',
  'navigation.errorfieldreset': 'Please enter the email address attached to your account and re-select the button',
  'navigation.errorrbackreset': 'Password changing failed. Please try again.',
  'navigation.reset-password': 'Forgot your password?',
  'navigation.forgot-password': 'Forgot your password?',
  'navigation.change-password': 'Change password',
  'navigation.activate': 'Account activation',
  'navigation.confirm': 'Account activation',
  'navigation.register': 'Registering',
  'navigation.resetasked-ok': 'OK',
  'navigation.resetasked-header': 'Check your email',
  'navigation.resetasked-text':
    'Email instructions how to restore the password was sent to given account if the given email address was found from the system.',
  'navigation.printpreview': 'Select and organize CV content for printing and download a printable PDF',
  'navigation.frontpage': 'Front Page',
  'navigation.help': 'Help',
  'navigation.teams': 'Teams',
  'navigation.accountSettings': 'Account Settings',
  'navigation.close': 'Close',
  'navigation.company': 'Company',
  'navigation.rememberMe': 'Remember me',
  'navigation.company-admin': 'Company',
  'navigation.salespool': 'Sales pool',
  'profile.fillingWizard.selectSkills': 'Select skills',
  'profile.fillingWizard.selectskill': 'Select a skill',
  'profile.fillingWizard.selectrole': 'Select a role',
  'profile.fillingWizard.selectindustry': 'Select a industry',
  'profile.fillingWizard.skill': 'Skill',
  'profile.fillingWizard.role': 'Role',
  'profile.fillingWizard.industry': 'Industry',
  'profile.fillingWizard.skill.search': 'Search for skill',
  'profile.fillingWizard.role.search': 'Search for role',
  'profile.fillingWizard.industry.search': 'Search for industry',
  'profile.fillingWizard.role.recommendations': 'Role recommendations',
  'profile.fillingWizard.skill.recommendations': 'Skill recommendations',
  'profile.fillingWizard.industry.recommendations': 'Industry recommendations',
  'profile.fillingWizard.skip': 'Skip profile filling wizard',
  'profile.fillingWizard.save': 'Create CV with {{count}} items',
  'profile.fillingWizard.update': 'Update CV with {{count}} items',
  'profile.fillingWizard.title': 'Profile filling wizard',
  'profile.fillingWizard.loadMore': 'Load more',
  'profile.fillingWizard.welcome': 'WELCOME TO CALEO',
  'profile.fillingWizard.instruction': 'Select a role that best fits your skills.',
  'profile.fillingWizard.defaultLevel': 'Level',
  'profile.fillingWizard.defaultInterest': 'Interest',
  'profile.fillingWizard.defaultExperience': 'Experience (months)',
  'profile.fillingWizard.helperText':
    'Select default values that are assigned to each added item. You can adjust values separately from the profile page.',
  'profile.noCv': 'This profile has no CVs.',
  'profile.lastUpdate': 'Last updated by',
  'profile.draft': 'Draft',
  'profile.published': 'Finished',
  'profile.companyId': 'Company ID',
  'profile.progressTitle': 'Profile progress',
  'profile.progress.info':
    'Markings are based on Finnish and English translations, for section to be marked as complete both languages must be filled.',
  'profile.progress.info2':
    'Education section is marked as complete if there is at least one degree, course or certificate fully filled.',
  'profile.parseCV': 'Parse new CV',
  'profile.addToPool': 'Add to salespool',
  'profile.print': 'Print CV',
  'profile.parseModal': 'Parse CV',
  'profile.about.addLinkTooltip': 'Add a personal link such as your website, portfolio or social media to your profile',
  'profile.about.noPrice': 'Pricing is not set.',
  'profile.modal.editTitle': 'Personal Information',
  'profile.modal.firstName': 'First name',
  'profile.modal.lastName': 'Last name',
  'profile.modal.telephone': 'Phone',
  'profile.modal.telephoneError': 'Please enter a valid phone number',
  'profile.modal.streetAddress': 'Street Address',
  'profile.modal.postalCode': 'Postal Code',
  'profile.modal.city': 'City',
  'profile.modal.country': 'Country',
  'profile.modal.about': 'Description',
  'profile.modal.save': 'Save Changes',
  'profile.modal.help': 'Help',
  'profile.modal.close': 'Close',
  'profile.modal.charLeft': 'Characters left (Recommended):',
  'profile.modal.pricingInformation': 'Only sales role is able to see pricing information.',
  'profile.modal.ProfileRoles': 'Role',
  'profile.modal.role': 'Role',
  'allocation.personal': 'Absence',
  'allocation.project': 'Assignment',
  'allocation.other': 'Other work',
  'allocation.other.info': 'Use <1>other work</1> type to do lightweight resourcing.',
  'allocation.assignmentId': 'Assignment',
  'allocation.customer': 'Customer',
  'allocation.status': 'Status',
  'allocation.percent': 'Allocation %',
  'allocation.expectedAllocation': 'Expected allocation',
  'allocation.totalAllocation': 'Total allocation',
  'allocation.information': 'Information',
  'allocation.description': 'Allocation description',
  'allocation.startDate': 'Start date',
  'allocation.endDate': 'End date',
  'allocation.state': 'State',
  'allocation.type': 'Type',
  'allocation.state.0': 'Tentative',
  'allocation.state.1': 'Verified',
  'allocation.own': 'Absence',
  'allocation.own.info': 'Use <1>Absence</1> type to mark vacations or absences.',
  'allocation.ownProject': 'Absence',
  'allocation.roleId': 'Role',
  'allocation.showAllocatedRoles': 'Show allocated roles',
  'allocation.addTitle': 'Add allocation',
  'allocation.editTitle': 'Edit allocation',
  'allocation.proposal.remove': 'Remove person from {{assignmentName}} proposal.',
  'allocation.pending': 'Tentative allocation, status pending.',
  'allocation.title': 'Allocations',
  'allocation.ownAllocations': 'Own personal allocations',
  'allocation.user': 'User',
  'allocation.showOutdated': 'Show outdated',
  'allocation.toAssignment': 'To assignment',
  'allocation.showActive': 'Show active',
  'allocation.ownCompany': 'Own company',
  'allocation.selectScope': 'Select scope',
  'allocation.networkScope': 'Add scope',
  'allocation.notes': 'Notes',
  'allocation.filter': 'Filter',
  'allocation.scope': 'Scope',
  'allocation.results': 'Results',
  'skills.title': 'Skills',
  'profile.skill.level': 'Level',
  'profile.skill.lastUsed': 'Last used',
  'profile.skill.experience': 'Experience',
  'profile.skill.month': 'Month',
  'profile.skill.months': 'Months',
  'profile.skill.month.abbr': 'mo.',
  'profile.skill.months.abbr': 'mos.',
  'profile.skill.unspecified': 'unspecified',
  'profile.skill.interest': 'Interest',
  'profile.skill.fundamental.description': 'Only minor practical experience and fundamental knowledge.',
  'profile.industry.fundamental.description': 'Only minor practical experience and fundamental knowledge.',
  'profile.role.fundamental.description': 'Only minor practical experience and fundamental knowledge.',
  'profile.skill.novice.description': 'Some practical experience.',
  'profile.role.novice.description': 'Some practical experience.',
  'profile.industry.novice.description': 'Some practical experience.',
  'profile.skill.intermediate.description': 'Proven practical experience.',
  'profile.role.intermediate.description': 'Proven practical experience.',
  'profile.industry.intermediate.description': 'Proven practical experience.',
  'profile.skill.advanced.description': 'Strong practical experience, capability to handle demanding tasks.',
  'profile.role.advanced.description': 'Strong practical experience, capability to handle demanding tasks.',
  'profile.industry.advanced.description': 'Strong practical experience, capability to handle demanding tasks.',
  'profile.skill.expert.description': 'Very strong practical experience, deep understanding and expertise.',
  'profile.industry.expert.description': 'Very strong practical experience, deep understanding and expertise.',
  'profile.role.expert.description': 'Very strong practical experience, deep understanding and expertise.',
  'profile.skill.fundamental': 'Fundamental',
  'profile.skill.novice': 'Novice',
  'profile.skill.intermediate': 'Intermediate',
  'profile.skill.advanced': 'Advanced',
  'profile.skill.expert': 'Expert',
  'profile.skill.year': 'Year',
  'profile.skill.years': 'Years',
  'profile.skill.years.abbr': 'yr.',
  'profile.skill.levels': 'Skill levels',
  'profile.skill.card.nameColumn': 'Skill',
  'profile.skill.modal.addTitle': 'Add skills',
  'profile.skill.modal.editTitle': 'Edit skill',
  'profile.skill.modal.skillId': 'Skill name',
  'profile.skill.modal.experienceMonths': 'Experience',
  'profile.skill.modal.level': 'Level',
  'profile.skill.modal.lastUsed': 'Last used',
  'profile.skill.modal.save': 'Save Skills',
  'profile.skill.modal.addSkill': 'Add skill',
  'profile.skill.modal.delete': 'Delete',
  'profile.skill.modal.update': 'Update Skill',
  'profile.skill.modal.help': 'Help',
  'profile.skill.modal.deletionError': 'Can not delete the skill. It is being used in projects.',
  'profile.skill.modal.deletionErrorHelp': 'Delete the skill from projects first.',
  'profile.skill.modal.experience.months': 'Months',
  'profile.skill.modal.experience.years': 'Years',
  'profile.skill.modal.experience.days': 'Days',
  'profile.skill.modal.experience.manDays': 'Man-day',
  'profile.skill.modal.experience.manMonths': 'Man-month',
  'profile.skill.modal.searchSkill': 'Search and add new skill',
  'profile.skill.interest.low': 'Low Interest',
  'profile.skill.interest.slight': 'Slight Interest',
  'profile.skill.interest.medium': 'Medium Interest',
  'profile.skill.interest.high': 'High Interest',
  'profile.skill.interest.veryHigh': 'Very High Interest',
  'profile.skill.massUpdate.addTitle': 'Add experience',
  'profile.skill.massUpdate.lastUpdated': 'Last updated',
  'profile.skill.massUpdate.experienceGained': 'Experience gained',
  'profile.skill.massUpdate.searchSkills': 'Search skills',
  'profile.skill.massUpdate.newSkill': 'New skill',
  'profile.skill.massUpdate.experienceMonthsGained': 'Experience months gained',
  'profile.skill.massUpdate.updateLastUsed': 'Update last used',
  'profile.skill.info': 'Levels description',
  'profile.skill.update': 'Add experience',
  'profile.skills.add': 'Add skill',
  'industries.title': 'Industries',
  'profile.industry.level': 'Level',
  'profile.industry.lastUsed': 'Last used',
  'profile.industry.experience': 'Experience',
  'profile.industry.month': 'Month',
  'profile.industry.months': 'Months',
  'profile.industry.month.abbr': 'mo.',
  'profile.industry.months.abbr': 'mos.',
  'profile.industry.unspecified': 'unspecified',
  'profile.industry.interest': 'Interest',
  'profile.industry.year': 'Year',
  'profile.industry.levels': 'Industry skill levels',
  'profile.industry.card.nameColumn': 'Industry',
  'profile.industry.modal.addTitle': 'Add industries',
  'profile.industry.modal.editTitle': 'Edit industry',
  'profile.industry.modal.skillId': 'Industry name',
  'profile.industry.modal.experienceMonths': 'Experience',
  'profile.industry.modal.level': 'Level',
  'profile.industry.modal.lastUsed': 'Last used',
  'profile.industry.modal.save': 'Save Industries',
  'profile.industry.modal.addSkill': 'Add industry',
  'profile.industry.modal.delete': 'Delete',
  'profile.industry.modal.update': 'Update Industry',
  'profile.industry.modal.help': 'Help',
  'profile.industry.modal.deletionError': 'Can not delete the industry. It is being used in projects.',
  'profile.industry.modal.deletionErrorHelp': 'Delete the industry from projects first.',
  'profile.industry.modal.searchSkill': 'Search and add new industry',
  'profile.industry.massUpdate.addTitle': 'Add experience',
  'profile.industry.massUpdate.lastUpdated': 'Last updated',
  'profile.industry.massUpdate.experienceGained': 'Experience gained',
  'profile.industry.massUpdate.searchSkills': 'Search industries',
  'profile.industry.massUpdate.newSkill': 'New industry',
  'profile.industry.massUpdate.experienceMonthsGained': 'Experience months gained',
  'profile.industries.add': 'Add industry',
  'roles.title': 'Roles',
  'profile.role.level': 'Level',
  'profile.role.lastUsed': 'Last used',
  'profile.role.experience': 'Experience',
  'profile.role.month': 'Month',
  'profile.role.months': 'Months',
  'profile.role.month.abbr': 'mo.',
  'profile.role.months.abbr': 'mos.',
  'profile.role.unspecified': 'unspecified',
  'profile.role.interest': 'Interest',
  'profile.role.year': 'Year',
  'profile.role.levels': 'Role skill levels',
  'profile.role.card.nameColumn': 'Role',
  'profile.role.modal.addTitle': 'Add roles',
  'profile.role.modal.editTitle': 'Edit role',
  'profile.role.modal.skillId': 'Role name',
  'profile.role.modal.experienceMonths': 'Experience',
  'profile.role.modal.level': 'Level',
  'profile.role.modal.lastUsed': 'Last used',
  'profile.role.modal.save': 'Save Roles',
  'profile.role.modal.addSkill': 'Add role',
  'profile.role.modal.delete': 'Delete',
  'profile.role.modal.update': 'Update Role',
  'profile.role.modal.help': 'Help',
  'profile.role.modal.deletionError': 'Can not delete the role. It is being used in projects.',
  'profile.role.modal.deletionErrorHelp': 'Delete the role from projects first.',
  'profile.role.modal.searchSkill': 'Search and add new role',
  'profile.role.massUpdate.addTitle': 'Add experience',
  'profile.role.massUpdate.lastUpdated': 'Last updated',
  'profile.role.massUpdate.experienceGained': 'Experience gained',
  'profile.role.massUpdate.searchSkills': 'Search roles',
  'profile.role.massUpdate.newSkill': 'New role',
  'profile.role.massUpdate.experienceMonthsGained': 'Experience months gained',
  'profile.roles.add': 'Add role',
  'project.title': 'Projects',
  'profile.project.modal.addTitle': 'Add project',
  'profile.project.modal.editTitle': 'Edit project',
  'profile.project.modal.name': 'Project name',
  'profile.project.modal.startDate': 'Start date',
  'profile.project.modal.endDate': 'End date',
  'profile.project.modal.employerName': 'Employer',
  'profile.project.modal.customerName': 'Customer',
  'profile.project.modal.role': 'Role',
  'profile.project.modal.description': 'Description',
  'profile.project.modal.save': 'Save Project',
  'profile.project.modal.addskill': 'Add Skill',
  'profile.project.modal.addindustry': 'Add Industry',
  'profile.project.modal.addindustry.placeholder': 'Find industries',
  'profile.project.modal.addskill.placeholder': 'Find skills',
  'profile.project.modal.addrole.placeholder': 'Find roles',
  'profile.project.modal.addrole': 'Add Role',
  'profile.project.modal.charLeft': 'Characters left (Recommended):',
  'profile.project.modal.skills': 'Skills',
  'profile.project.modal.industries': 'Industries',
  'profile.project.modal.roles': 'Role tags',
  'profile.project.modal.primaryRole': 'Role',
  'profile.project.add': 'Add project',
  'assignments.overview': 'Overview',
  'assignment.modal.addTitle': 'New assignment',
  'assignment.modal.roles': 'Roles',
  'assignment.modal.generalTitle': 'General',
  'assignment.modal.customerTitle': 'Customer',
  'assignment.modal.rolesTitle': 'Roles',
  'assignment.modal.attachmentsTitle': 'Attachments',
  'assignment.modal.editTitle': 'Edit assignment',
  'assignment.modal.shareTitle': 'Copy and share assignment',
  'assignment.modal.copyTitle': 'Copy assignment',
  'assignment.modal.share': 'Share to networks',
  'assignment.modal.copy.save': 'Create new assignment',
  'assignment.modal.share.save': 'Share and save',
  'assignment.modal.customerId': 'Customer',
  'assignment.modal.copy': 'Copy',
  'assignment.share.broker': 'Assignment is shared and published to networks.',
  'assignment.share.info': 'Assignment share is waiting for maintainers approval.',
  'assignment.internal': 'Show internal',
  'assignment.external': 'Show external',
  'assignment.shared': 'Show shared',
  'assignment.outdated': 'Show outdated',
  'assignment.favorite': 'Show favorites',
  'assignment.contactPeople': 'Filter by contact people',
  'assignment.filterBySkill': 'Filter by skills',
  'assignment.notifySelector': 'Select users to notify',
  'assignment.internal.tooltip': 'Not shared assignments from own organization.',
  'assignment.external.tooltip': 'Assignments that are shared to your organization.',
  'assignment.shared.tooltip': 'Shared assignments from own organization.',
  'assignment.outdated.tooltip': 'Assignments that are overdue by deadline.',
  'assignment.favorite.tooltip': 'Assignments that are set as favorites.',
  'assignment.description': 'Assignment description',
  'assignment.autoRejectEmail': '(Automatically closes proposals and sends closing notifications)',
  'assignment.modal.view': 'View assignment',
  'assignment.modal.viewPropose': 'View and propose to assignment',
  'assignment.modal.next': 'Next',
  'assignment.modal.save': 'Save',
  'assignment.modal.name': 'Assignment name',
  'assignment.modal.startDate': 'Start Date',
  'assignment.modal.endDate': 'End Date',
  'assignment.modal.workloadEstimate': 'Workload estimate (days)',
  'assignment.modal.country': 'Country',
  'assignment.modal.city': 'City',
  'assignment.modal.subcontractorAllowed': 'Subcontractors allowed',
  'assignment.modal.remoteAllowed': 'Remote allowed',
  'assignment.modal.deadline': 'Deadline',
  'assignment.modal.description': 'Description',
  'assignment.modal.customer': 'Customer',
  'assignment.modal.customerContactId': 'Customer contact',
  'assignment.modal.additionalInfo': 'Additional info',
  'assignment.modal.location': 'Location',
  'assignment.modal.attachments.description': 'Description',
  'assignment.modal.attachments.upload-action': 'Upload',
  'assignment.modal.attachments.download-action': 'Download',
  'assignment.modal.roles.hourlyPrice': 'Price',
  'assignment.modal.roles.allocation': 'Allocation',
  'assignment.modal.roles.createCopy': 'Create a new role from copy',
  'assignment.modal.personInCharge': 'Person in charge',
  'assignment.modal.contactPeople': 'Contact people',
  'assignment.modal.contactName': 'Name',
  'assignment.modal.publicView': 'Viewable without login',
  'assignment.modal.contactPersonId': 'Contact person',
  'assignment.modal.notifyTo': 'Send email notification to',
  'assignment.modal.publicUrl': 'Public URL',
  'assignment.modal.copyToClipboard': 'Copy to clipboard',
  'assignment.modal.lastChangedBy': 'Status last changed by: {{name}}',
  'assignment.modal.statusBySystem': 'System',
  'assignment.modal.template': 'Template',
  'assignment.modal.templateName': 'Template name',
  'assignment.modal.templateCreate': 'Create',
  'assignment.modal.templateUpdate': 'Update',
  'assignment.modal.fileProposalAllowed': 'File proposal allowed',
  'assignment.modal.basicInfo': 'Basic information',
  'assignment.modal.details': 'Details',
  'assignment.modal.sharingOptions': 'Sharing options',
  'assignment.modal.moreDetails': 'Description',
  'assignment.modal.attachments': 'Attachments',
  'assignment.modal.useTemplate': 'Use template',
  'assignment.modal.helperTextContactPerson': 'Network sharing so contact people set in sharing options.',
  'assignment.modal.status': 'Status',
  'assignment.modal.info': 'Info',
  'assignment.modal.contactInfo': 'Contact info',
  'assignment.modal.people': 'People',
  'assignment.modal.favorite': 'Add to favorites',
  'assignment.modal.unfavorite': 'Remove from favorites',
  'assignment.table.name': 'Name',
  'assignment.table.customer': 'Customer',
  'assignment.table.status': 'Status',
  'assignment.table.created': 'Accepting proposals',
  'assignment.table.inProgress': 'Offer in progress',
  'assignment.table.resolved': 'Resolved',
  'assignment.table.roles': 'Roles',
  'assignment.table.inCharge': 'In Charge',
  'assignment.table.startDate': 'Start Date',
  'assignment.table.endDate': 'End Date',
  'assignment.table.attachments': 'Attachments',
  'assignment.table.deadline': 'Deadline',
  'assignment.table.roleTooltip.header': 'Show search results with',
  'assignment.table.roleTooltip.location': 'Location',
  'assignment.table.roleTooltip.skills': 'Skills',
  'assignment.table.createdAt': 'Created at',
  'assignment.roles.table.name': 'Role Name',
  'assignment.roles.add': 'Add role',
  'assignment.roles.applyChanges': 'Apply changes',
  'assignment.roles.table.allocationPercentage': 'Allocation %',
  'assignment.roles.table.negotiable': 'Negotiable',
  'allocationPercentage.minValue': 'Value must be bigger than 0%',
  'allocationPercentage.maxValue': 'Maximum value is 100%',
  'assignment.roles.table.hourlyPrice': 'Price €/h',
  'assignment.roles.table.skills': 'Skills',
  'assignment.roles.table.languages': 'Languages',
  'assignment.roles.table.description': 'Description',
  'assignment.roles.table.assignedPeople': 'Assigned',
  'assignment.roles.table.assignedPeople.name': 'Name',
  'assignment.roles.table.assignedPeople.allocation': 'Allocation',
  'assignment.roles.table.assignedPeople.state': 'State',
  'assignment.roles.table.assignedPeople.startDate': 'Start Date',
  'assignment.roles.table.assignedPeople.endDate': 'End Date',
  'assignment.roles.allocations': 'Role Allocations',
  'assignment.roles.allocation.helperText':
    'This role can not be deleted or edited because it is used in one or more allocations. For editing or removing the role, all related allocations must be deleted.',
  'assignment.skill.name': 'Role Name',
  'assignment.skill.allocationPercentage': 'Allocation %',
  'assignment.skill.hourlyPrice': 'Price €/h',
  'assignment.skill.description': 'Description',
  'assignment.skill.editTitle': 'Editing role',
  'assignment.skill.addTitle': 'Add new role',
  'assignment.skill.role.view': 'View role',
  'assignment.role.requirement.skill.skillId': 'Skill',
  'assignment.role.requirement.skill.level': 'Level',
  'assignment.role.requirement.skill.experienceMonths': 'Experience',
  'assignment.role.requirement.industry.skillId': 'Industry',
  'assignment.role.requirement.industry.level': 'Level',
  'assignment.role.requirement.industry.experienceMonths': 'Experience',
  'assignment.role.requirement.role.skillId': 'Role',
  'assignment.role.requirement.role.level': 'Level',
  'assignment.role.requirement.role.experienceMonths': 'Experience',
  'assignment.role.requirement.language.languageCode': 'Language',
  'assignment.role.requirement.language.level': 'Level',
  'assignment.role.allocation.verified': 'Allocation verified',
  'assignment.role.allocation.multipleVerified': 'Multiple allocations are verified or tentative',
  'assignment.role.allocation.tentative': 'One or more tentative allocations',
  'assignment.role.allocation.deleteConfirm': 'Are you sure you want to delete allocation?',
  'assignment.role.description': 'Role description',
  'assignment.propose.openProfile': 'Open profile',
  'assignment.propose.noCandidates': 'Uh oh. No matching candidates.',
  'assignment.propose.noCandidates.description': 'Candidates must be available and the requested skills must match.',
  'assignment.propose.noCandidates.backButton': 'Back to assignments',
  'assignment.propose': 'Propose candidates',
  'assignment.propose.proposed': 'Proposed',
  'assignment.propose.confirmed': 'Verified',
  'assignment.propose.addTitle': 'Create proposal',
  'assignment.propose.role': 'Role',
  'assignment.propose.criteria': 'Search criteria',
  'assignment.propose.location': 'Location',
  'assignment.propose.remoteAllowed': 'Remote allowed',
  'assignment.propose.start': 'Start date',
  'assignment.propose.end': 'End date',
  'assignment.propose.allocationPercent': 'Allocation percent',
  'assignment.propose.additionalInformation': 'Additional information',
  'assignment.propose.minPrice': 'Minimum price €/h',
  'assignment.propose.maxPrice': 'Target price €/h',
  'assignment.propose.added': 'Added to proposal',
  'assignment.propose.add': 'Add to proposal',
  'assignment.propose.remove': 'Remove from proposal',
  'assignment.propose.save': 'Send proposal',
  'assignment.propose.update': 'Update proposal',
  'assignment.propose.roleCandidates': 'Role Candidates',
  'assignment.propose.role.note.createdAt': 'Last updated {{date}} by ',
  'assignment.propose.role.note.showVersion': 'Show version history',
  'assignment.propose.role.note.versionHistory': 'Version history',
  'assignment.propose.role.note.version': 'Version {{number}}',
  'assignment.propose.candidates': 'Show candidates',
  'assignment.propose.role.header': 'Proposals',
  'assignment.propose.role.requirements': 'Requirements',
  'assignment.propose.role.location': 'Location',
  'assignment.propose.role.remote': 'Remote allowed',
  'assignment.propose.role.startDate': 'Start date',
  'assignment.propose.role.endDate': 'End date',
  'assignment.propose.role.allocation': 'Allocation',
  'assignment.propose.role.downloadCV': 'Download CV',
  'assignment.propose.role.pricing': 'Pricing',
  'assignment.propose.role.match': 'Match',
  'assignment.propose.role.price': 'Hourly price',
  'assignment.propose.role.no-contact': 'The organization has no contact person and the proposer has been removed!',
  'assignment.propose.role.questions': 'Questions ({{yes}}/{{count}})',
  'assignment.propose.role.status': 'Status',
  'assignment.propose.role.createdAt': 'Created at',
  'assignment.propose.role.additionalInfo': 'Additional information',
  'assignment.propose.role.noEndDate': 'No end',
  'assignment.propose.role.contactPerson': 'Contact person',
  'assignment.propose.role.organization': 'Organization',
  'assignment.propose.role.email': 'Email',
  'assignment.propose.role.telephone': 'Telephone',
  'assignment.propose.price': 'Hourly price',
  'assignment.propose.bestOffer': 'best offer',
  'assignment.propose.info':
    'Tentative allocation will be added to the proposed users. Assignment owner will be in touch.',
  'assignment.propose.downloadAttachment': 'Download attachment',
  'assignment.propose.freelancerCopy': 'Send me a email copy of the proposal',
  'assignment.propose.sendCopiesTo': 'Send copy of the proposal to',
  'assignment.proposed.proposeToOwn': 'Propose on behalf of supplier',
  'assignment.propose.available': 'Show available candidates',
  'assignment.propose.showAll': 'Show all',
  'assignment.propose.filterName': 'Filter by name',
  'assignment.propose.status.proposed': 'Proposed',
  'assignment.propose.status.accepted': 'Accepted',
  'assignment.propose.status.rejected': 'Rejected',
  'assignment.propose.status.closed': 'Closed',
  'assignment.propose.reject': 'Reject',
  'assignment.propose.rejectConfirm': 'Reject proposal',
  'assignment.propose.rejectMessage': 'Reject message',
  'assignment.propose.assignmentDescription': 'Assignment description',
  'assignment.propose.roleDescription': 'Role description',
  'assignment.propose.assingmentResolved': 'Assignment resolved, proposing closed!',
  'assignment.propose.yesLabel': 'Reject',
  'assignment.propose.noLabel': 'Cancel',
  'assignment.propose.details': 'Assignment Details',
  'assignment.propose.roles': 'Roles',
  'assignment.propose.role.note': 'Notepad',
  'assignment.attachments.name': 'Name',
  'assignment.attachments.size': 'Size',
  'assignment.attachments.description': 'Description',
  'assignment.view.tabs.list': 'List',
  'assignment.view.tabs.people': 'People',
  'role.requirements': 'Requirements',
  'role.questionnaire': 'Questionnaire',
  'role.RoleQuestionnaire.addTitle': 'Questionnaire',
  'role.RoleQuestionnaire.info': 'Questions should be answerable with "yes" or "no".',
  'role.RoleQuestionnaire.required': 'Questionnaire answer required',
  'role.RoleQuestionnaire.question': 'Question',
  'role.RoleQuestionnaire.addQuestion': 'Add question',
  'role.RoleQuestionnaire.header': 'Questionnaire',
  'role.RoleQuestionnaire.required.info': 'Questionnaire is required for each candidate.',
  'role.RoleQuestionnaire.button': 'Questionnaire',
  'role.RoleQuestionnaire.currentlyanswering': 'Currently answering',
  'role.RoleQuestionnaire.withoutAnswer': 'No answer for: {{count}} candidates',
  'role.RoleQuestionnaire.backToTop': 'Back to top',
  'role.subcontractor.header': 'Subcontractor',
  'role.subcontractor.switch': 'Is candidate a subcontractor?',
  'role.subcontractor.submit': 'Save',
  'assignments.proposals': 'Proposals',
  'assignments.proposals.outdated': 'Show outdated',
  'assignments.proposals.viewProposal': 'View proposal',
  'assignments.proposals.openAssignment': 'Open assignment',
  'assignments.proposals.viewHeader': 'Proposal details',
  'assignments.proposals.pricing': 'Pricing',
  'assignments.proposals.additionalInfo': 'Additional information',
  'assignments.proposals.noInfo': 'No additional information',
  'assignments.proposals.edit': 'Edit proposal',
  'assignments.proposals.joinChat': 'Join chat',
  'proposals.table.assignment': 'Assignment',
  'proposals.table.created': 'Created',
  'proposals.table.role': 'Role',
  'proposals.table.startDate': 'Start date',
  'proposals.table.endDate': 'End date',
  'proposals.table.deadline': 'Deadline',
  'proposals.table.creator': 'Creator',
  'proposals.table.contactPeople': 'Contact people',
  'proposals.table.proposedCount': 'Proposed',
  'proposals.table.candidates': 'Candidates',
  'proposals.table.status': 'Status',
  'proposals.table.updated': 'Updated',
  'navigation.chat': 'Chat',
  'chat.join': 'Join',
  'chat.room': 'Chat room',
  'chat.room.deleteText': 'Are you sure you want to delete {{name}} room?',
  'chat.rooms': 'Rooms',
  'chat.invites': 'Invites',
  'chat.reconnect': 'Reconnect',
  'chat.users': 'Members',
  'chat.leave': 'Leave room',
  'chat.invite': 'Invite',
  'chat.roomName': 'Room name',
  'chat.members': 'Members',
  'chat.invited': 'Invited',
  'chat.edit': 'Edit',
  'chat.goto': 'Go to room',
  'chat.newMessage': 'New message in room',
  'chat.title': 'Chat',
  'chat.modal.addTitle': 'Add chat room',
  'chat.modal.editTitle': 'Edit chat room',
  'chat.modal.name': 'Room name',
  'chat.modal.roomMembers': 'Room members',
  'chat.modal.addMember': 'Add member',
  'chat.modal.room-exists-error': 'Chat room already exists!',
  'chat.modal.members-required': 'Room members required!',
  'chat.proposalConflict': 'You are assignment creator and proposer, could not find anyone to chat to!',
  'chat.cryto': 'All Messages are saved as encrypted strings in the database.',
  'chat.type.assignment':
    'This chat can be seen by all users that have access to the assignment. Please do not send anything confidetial to this chat.',
  'chat.type.proposal': 'This chat can only be seen by the proposer and assignment owner.',
  'chat.type.general': 'This is for general chat with invited users.',
  'chat.scrollToBottom': 'Scroll to bottom',
  'chat.connect': 'Connect',
  'chat.toAssignment': 'To assignment',
  'chat.user': 'User ',
  'chat.left': ' left',
  'chat.joined': ' joined',
  'chat.assignmentName': 'Assignment name: {{name}}',
  'chat.anonUser': 'User',
  'chat.messages': 'Messages',
  'attachments.description': 'Description',
  'attachments.description.placeholder': 'Please add a file first, then enter its description',
  'attachments.download-action': 'Download File',
  'attachments.upload-action': 'Upload File',
  'attachment.proposal.remove': 'Remove attachment from {{assignmentName}} proposal.',
  'education.title': 'Education',
  'degrees.title': 'Degrees',
  'certificates.title': 'Certificates',
  'courses.title': 'Courses',
  'profile.education.modal.startDate': 'Start date',
  'profile.education.modal.endDate': 'End date',
  'profile.education.modal.school': 'School',
  'profile.education.modal.degree': 'Degree',
  'profile.education.modal.description': 'Description',
  'profile.education.modal.editTitle': 'Edit degree',
  'profile.education.modal.addTitle': 'New degree',
  'profile.education.modal.charLeft': 'Characters left (Recommended):',
  'profile.education.add': 'Add education',
  'profile.course.modal.editTitle': 'Edit course',
  'profile.course.modal.addTitle': 'New course',
  'profile.course.modal.startDate': 'Start date',
  'profile.course.modal.endDate': 'End date',
  'profile.course.modal.course': 'Course',
  'profile.course.modal.organizer': 'Organizer',
  'profile.course.modal.description': 'Description',
  'profile.course.modal.charLeft': 'Characters left (Recommended):',
  'profile.certificate.modal.editTitle': 'Edit certificate',
  'profile.certificate.modal.addTitle': 'New certificate',
  'profile.certificate.modal.certificate': 'Certificate',
  'profile.certificate.modal.certifier': 'Certifier',
  'profile.certificate.modal.endDate': 'Expiration Date',
  'profile.certificate.modal.licenceNumber': 'Licence number',
  'profile.certificate.modal.url': 'URL',
  'profile.certificate.modal.issueDate': 'Issue Date',
  'profile.certificate.modal.description': 'Description',
  'profile.certificate.modal.charLeft': 'Characters left (Recommended):',
  'profile.freefi.text': 'Fast track your paper work and invoicing with Free!',
  'profile.freefi.button': 'Get started',
  'url.modal.editTitle': 'Edit web site',
  'url.modal.addTitle': 'Add web site',
  'url.modal.url': 'Web site',
  'url.modal.description': 'Description',
  'statistics.scoutTitle': 'Caleo Scout results',
  'statistics.scoutPlusTitle': 'Caleo Scout Plus results',
  'statistics.scoutUsers': 'Users',
  'statistics.allUsers': 'Kaikki käyttäjät',
  'statistics.user': 'User',
  'statistics.scoreNormal': 'Normal',
  'statistics.scorePressured': 'Pressured',
  'confirmation.yes': 'Yes',
  'confirmation.no': 'No',
  'confirmation.text': 'Are you sure you want to continue?',
  'confirmation.organization.text': 'Are you sure you want to delete organization?',
  'confirmation.user.data': 'User {{name}} has data!',
  'confirmation.skill.data': 'Skill {{name}} is used in projects',
  'confirmation.ok': 'Ok',
  'confirmation.site.data': 'Site has employees.',
  'confirmation.site.text': 'Please move employees first!',
  'confirmation.skill.text': 'Are you sure you want to continue?',
  'confirmation.skill.deleteText': 'Are you sure you want to delete {{name}} skill?',
  'confirmation.industry.data': 'Industry {{name}} is used in projects',
  'confirmation.industry.text': 'Are you sure you want to continue?',
  'confirmation.industry.deleteText': 'Are you sure you want to delete {{name}} industry?',
  'confirmation.organization.data': 'Organization is connected to employees, assignments or teams',
  'confirmation.assignments.data': 'Following assignments will be deleted.',
  'confirmation.teams.data': 'Following teams will be deleted.',
  'confirmation.role.data': 'Role {{name}} is used in projects',
  'confirmation.person.data': 'Following employees will be left into Caleo as freelancers.',
  'confirmation.role.text': 'Are you sure you want to continue?',
  'confirmation.role.deleteText': 'Are you sure you want to delete {{name}} role?',
  'no-undo': 'This action is irreversible.',
  'confirmation.user.text': 'All data associated with the user is deleted.',
  'confirmation.networkManage': 'User is removed from the SYSTEM not just the network!',
  'confirmation.networkManage.confirm': 'Please make sure the organization/freelancer approves the operation!',
  'site.person.name': 'Name',
  'site.person.email': 'Email',
  fieldRequired: 'Field is required!',
  fieldRequiredLabel: '{{label}} is required!',
  edit: 'Edit',
  open: 'Open',
  'login.failed': 'Login failed',
  'login.notActivated': 'Account not verified!',
  'login.wrongPassword': 'Wrong email or password!',
  'footer.support': 'Support',
  'error.header': 'Oops! Something went wrong.',
  'error.ok': 'Continue',
  'error.generic': 'Please contact support!',
  'error.postUserEmailInUse': 'Email is already in use!',
  'error.general': 'Please contact support with ErrorId: {{id}}',
  'error.positive': 'Give positive number',
  'error.biggerThanMin': 'Maximum price must be bigger than minimum price',
  'error.telephone.provideInInternationalFormat': 'Please enter the phone number in international format',
  save: 'Save',
  update: 'Save Changes',
  delete: 'Delete',
  saveAndAdd: 'Save and add new',
  changesSaved: 'Changes saved',
  add: 'Add',
  cancel: 'Cancel',
  savePromote: 'Save translation',
  'language.title': 'Languages',
  'language.editTitle': 'Edit Language',
  'language.addTitle': 'Add Language',
  'language.languageCode': 'Language',
  'language.name': 'Language',
  'language.level': 'Level',
  'language.basic': 'Basics',
  'language.moderate': 'Moderate',
  'language.good': 'Good',
  'language.excellent': 'Excellent',
  'language.native': 'Native',
  'language.addLanguage': 'Add language',
  'language.select': 'Select language',
  'language.description.a2':
    'Can understand sentences and frequently used expressions related to areas of most immediate relevance (e.g. very basic personal and family information, shopping, local geography, employment). Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters. Can describe in simple terms aspects of his/her background, immediate environment and matters in areas of immediate need.',
  'language.description.b1':
    'Can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc. Can deal with most situations likely to arise whilst travelling in an area where the language is spoken. Can produce simple connected text on topics which are familiar or of personal interest. Can describe experiences and events, dreams, hopes and ambitions and briefly give reasons and explanations for opinions and plans.',
  'language.description.b2':
    'Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in his/her field of specialization. Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party. Can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and Independent disadvantages of various options.',
  'language.description.c1':
    'Can understand a wide range of demanding, longer texts, and recognize implicit meaning. Can express him/herself fluently and spontaneously without much obvious searching for expressions. Can use language flexibly and effectively for social, academic and professional purposes. Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organizational patterns, connectors and cohesive devices.',
  'language.description.c2':
    'Can understand with ease virtually everything heard or read. Can summarize information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation. Can express him/herself spontaneously, very fluently and precisely, differentiating Finer shades of proficient meaning even in more complex situations.',
  'assignments.title': 'Assignments',
  'employees.title': 'Employees',
  'resources.searchEmployees': 'Search Employees',
  'resources.searchEmployees.simple': 'Search consultants',
  'resources.searchEmployees.simpleView': 'Simple view',
  'resources.searchEmployees.advancedView': 'Advanced settings',
  'resources.searchExperts': 'Search Experts',
  'resources.searchEmployees.typeHere': 'Type here',
  'resources.searchEmployees.typeOrSelect': 'Type or select',
  'resources.searchEmployees.disabled': '-- DISABLED --',
  'resources.searchEmployees.add': 'Add',
  'resources.searchEmployees.availableOnly': 'Show available people only',
  'resources.searchEmployees.showDraftCVs': 'Show draft CVs',
  'resources.searchEmployees.aiAssisted': 'Experimental AI-assisted near matches',
  'resources.searchEmployees.name': 'Name',
  'resources.searchEmployees.location': 'Location',
  'resources.searchEmployees.networks': 'Search From',
  'resources.searchEmployees.availability': 'Availability',
  'resources.searchEmployees.startDate': 'Starting from',
  'resources.searchEmployees.endDate': 'Until',
  'resources.searchEmployees.businessArea': 'Bussiness Area',
  'resources.searchEmployees.customer': 'Organization',
  'resources.searchEmployees.skill.skillId': 'Skill Requirement',
  'resources.searchEmployees.skill.required': 'Required',
  'resources.searchEmployees.all.skillId': 'Skills, industries, roles',
  'resources.searchEmployees.skill.experienceMonths': 'Required experience in months',
  'resources.searchEmployees.certificate': 'Certificate Requirement',
  'resources.searchEmployees.help': 'Help',
  'resources.searchEmployees.clear': 'Clear',
  'resources.searchEmployees.search': 'Search',
  'resources.searchEmployees.allOrganizations': 'All allowed organizations',
  'resources.searchEmployees.networkOrganizations': 'Network organizations',
  'resources.searchEmployees.userOrganizations': 'My organizations only',
  'resources.searchEmployees.requiredField': 'Required field',
  'resources.searchEmployees.degree': 'Degree requirement',
  'resources.searchEmployees.course': 'Course requirement',
  'resources.searchEmployees.industry.skillId': 'Industry requirement',
  'resources.searchEmployees.language.languageCode': 'Language requirement',
  'resources.searchEmployees.role.skillId': 'Role requirement',
  'resources.searchEmployees.project.count': 'Count',
  'resources.searchEmployees.otherCriterias': 'other search criteria set.',
  'resources.searchResults.perfectResults': '{{count}} Perfect results',
  'resources.searchResults.allResults': '{{count}} Total results (partial and perfect match)',
  'resources.searchResults.allMatches': 'All Matches',
  'resources.searchResults.perfectMatches': 'Perfect Matches',
  'resources.searchResults.partialMatches': 'Partial Matches',
  'resources.searchResults.aiMatches': 'AI Matches',
  'resources.searchResults.allocations': 'Allocations',
  'resources.searchResults.perfectMatch': 'Perfect match',
  'resources.searchResults.missingRole': 'Consultant',
  'resources.searchResults.emptyResult.title': `Didn't find what you were looking for?`,
  'resources.searchResults.emptyResult.text': `Request a free shortlist of candidates`,
  'resources.searchResults.emptyResult.request': `Send a request`,
  'resources.searchResults.skill': 'Skill',
  'resources.searchResults.experience': 'Experience',
  'resources.searchResults.level': 'Level',
  'resources.searchResults.interest': 'Interest',
  'resources.searchResults.lastUsed': 'Last used',
  'resources.userOrganizationResults.title': 'Your organization',
  'resources.userOrganizationResults.available': 'Show available people only',
  'search.propose.addTitle': 'Propose assignment',
  'search.propose.city': 'City',
  'search.propose.remoteAllowed': 'Remote allowed',
  'search.propose.percent': 'Allocation %',
  'search.propose.pricePerHour': 'Price €/h',
  'search.propose.startDate': 'Start Date',
  'search.propose.duration': 'Duration (months)',
  'search.propose.extensionPossible': 'Extension possible',
  'search.propose.information': 'Additional information',
  'search.propose.submit': 'Submit',
  'search.propose.success': 'Proposal submitted',
  'search.propose.save': 'Send',
  'search.propose.moreOptions': 'More options',
  'search.request.city': 'City',
  'search.request.remoteAllowed': 'Remote allowed',
  'search.request.percent': 'Allocation %',
  'search.request.pricePerHour': 'Price €/h',
  'search.request.startDate': 'Start Date',
  'search.request.duration': 'Duration (months)',
  'search.request.extensionPossible': 'Extension possible',
  'search.request.information': 'Additional information',
  'search.request.submit': 'Submit',
  'search.request.success': 'Request submitted',
  'search.request.addTitle': 'Request shortlist',
  'search.request.save': 'Send',
  'search.request.moreOptions': 'More options',
  'search.skillAlreadyExists': 'That skill is already selected',
  'search.roleAlreadyExists': 'That role is already selected',
  'search.certificateAlreadyExists': 'That certificate is already selected',
  'search.languageAlreadyExists': 'That language is already selected',
  'search.degreeAlreadyExists': 'That degree is already selected',
  'search.courseAlreadyExists': 'That course is already selected',
  'search.industryAlreadyExists': 'That industry is already selected',
  'search.loadMore': 'Load more',
  'search.noNetworks': 'No search area specified.',
  'search.experienceMonths': 'Experience months',
  'search.required': 'Importance',
  'search.industryMatch': 'Matching industries',
  'search.roleMatch': 'Matching roles',
  'search.languageMatch': 'Matching languages',
  'search.degreeMatch': 'Matching degrees',
  'search.courseMatch': 'Matching courses',
  'search.projectCountMatch': 'matching projects.',
  'search.projectCount': 'Required projects',
  'search.sortingPerfect': 'Sort perfect by',
  'search.allSkills': 'Search skills, industries or roles',
  'team.title': 'Teams',
  'team.noTeam': 'User does not have any teams.',
  'team.modal.addTitle': 'Add team',
  'team.modal.editTitle': 'Edit team',
  'team.modal.delete': 'Delete',
  'team.modal.update': 'Update',
  'team.modal.save': 'Save',
  'team.modal.name': 'Team name',
  'team.modal.description': 'Description',
  'team.modal.manager': 'Team lead',
  'team.modal.hidden': 'Hidden',
  'team.modal.addMember': 'Add member',
  'team.modal.organization': 'Organization',
  'team.modal.charLeft': 'Characters left (Recommended):',
  'team.modal.showAll': 'Show all teams',
  'team.modal.person': 'Person',
  'team.modal.managerOnClick': 'Change to team lead by clicking on the name.',
  'team.modal.limitAssignments': 'Limit assignments visibility to only assignments defined for this team',
  'team.teamLeads': 'Team Leads',
  'team.teamMembers': 'Team Members',
  'team.members': 'Members',
  'team.membersCount': 'Members',
  'team.openProfile': 'Open profile',
  'team.allTeams': 'All teams',
  'team.filters': 'Filter teams',
  'team.skillPicker': 'Skills',
  'team.rolePicker': 'Roles',
  'team.industryPicker': 'Industries',
  'team.certificateField': 'Certificates',
  'team.skills': 'Skills',
  'team.roles': 'Roles',
  'team.industries': 'Industries',
  'team.certificates': 'Certificates',
  'team.noName': 'No name',
  'team.listHeader': 'Team list',
  'team.name': 'Name',
  'team.description': 'Description',
  'team.info': 'Details',
  'team.limitAssignments': 'Assignments visibility limited to only assignments defined for this team',
  'team.limitAssignmentShort': 'Limited assignment visibility',
  'admin.title': 'Admin',
  'picker.selectAll': 'Select All',
  'picker.deselectAll': 'Deselect All',
  'custom-table.all': 'All',
  'custom-table.labelRowsSelect': 'Rows per page:',
  'custom-table.labelDisplayedRows': '{{from}}-{{to}} of {{count}}',
  'custom-table.delete': 'Delete',
  'custom-table.edit': 'Edit',
  'custom-table.labelSortBy': 'Sort by column',
  'custom-table.emptyDataSourceMessage': 'No records to display',
  'custom-table.searchTooltip': 'Search',
  'custom-table.searchPlaceholder': 'Search',
  'custom-table.nRowsSelected': '{0} row(s) selected',
  'custom-table.records': 'records...',
  'custom-table.rowsPerPage': 'Rows per page',
  'custom-table.clear': 'Clear',
  'monthYear.month': 'MM/YYYY',
  'monthYear.day': 'DD/MM/YYYY',
  'dateResolution.fullDate': 'Full date',
  'dateResolution.month': 'Month and year',
  'dateResolution.year': 'Year',
  'reference.title': 'Recommendations',
  'profile.reference.modal.editTitle': 'Edit Recommendation',
  'profile.reference.modal.addTitle': 'Add Recommendation',
  'profile.reference.modal.firstName': 'First name',
  'profile.reference.modal.lastName': 'Last name',
  'profile.reference.modal.letter': 'Letter',
  'profile.reference.modal.company': 'Company',
  'profile.reference.modal.charLeft': 'Characters left (Recommended):',
  'profile.reference.add': 'Add reference',
  'employer.title': 'Work Experience',
  'profile.employer.role': 'Role',
  'profile.employer.roles': 'Role-tags',
  'profile.employer.modal.editTitle': 'Edit Work Experience',
  'profile.employer.modal.addTitle': 'Add Work Experience',
  'profile.employer.modal.close': 'Close',
  'profile.employer.modal.name': 'Employer',
  'profile.employer.modal.monthyear': 'Dates',
  'profile.employer.modal.startDate': 'Start Date',
  'profile.employer.modal.endDate': 'End Date',
  'profile.employer.modal.role': 'Role tags',
  'profile.employer.modal.primaryRole': 'Rooli',
  'profile.employer.modal.description': 'Description',
  'profile.employer.modal.help': 'Help',
  'profile.employer.modal.delete': 'Delete',
  'profile.employer.modal.save': 'Save Changes',
  'profile.employer.modal.makeHistory': 'Make History',
  'profile.employer.modal.charLeft': 'Characters left (Recommended):',
  'profile.employer.model.convertToProject': 'Convert to project',
  'profile.employer.add': 'Add employer',
  'months.0': 'January',
  'months.1': 'February',
  'months.2': 'March',
  'months.3': 'April',
  'months.4': 'May',
  'months.5': 'June',
  'months.6': 'July',
  'months.7': 'August',
  'months.8': 'September',
  'months.9': 'October',
  'months.10': 'November',
  'months.11': 'December',
  'months.short.0': 'Jan',
  'months.short.1': 'Feb',
  'months.short.2': 'Mar',
  'months.short.3': 'Apr',
  'months.short.4': 'May',
  'months.short.5': 'Jun',
  'months.short.6': 'Jul',
  'months.short.7': 'Aug',
  'months.short.8': 'Sept',
  'months.short.9': 'Oct',
  'months.short.10': 'Nov',
  'months.short.11': 'Dec',
  'weekdaysShort.1': 'M',
  'weekdaysShort.2': 'T',
  'weekdaysShort.3': 'W',
  'weekdaysShort.4': 'T',
  'weekdaysShort.5': 'F',
  'weekdaysShort.6': 'S',
  'weekdaysShort.0': 'S',
  'admin.employee.addTitle': 'Add Employee',
  'admin.employee.editTitle': 'Edit Employee',
  'admin.employee.firstName': 'First Name',
  'admin.employee.lastName': 'Last Name',
  'admin.employee.telephone': 'Phone number',
  'admin.employee.email': 'Email',
  'admin.employee.organizationId': 'Organization',
  'admin.employee.siteId': 'Site',
  'admin.employee.name': 'Name',
  'admin.employee.role': 'Role',
  'admin.employee.lastLogin': 'Last login',
  'admin.employee.resetPassword': 'Re-send activation email (non-activated account) or send reset password email',
  'admin.employee.passwordReset': 'Activation or reset password email was sent!',
  'admin.employee.edit': 'Edit employee',
  'admin.employee.add': 'Add employee',
  'admin.employee.visibility': 'Network visibility',
  'admin.employee.networkInfo':
    'User is shown in networks, your company belongs to. More information from "my-networks"-page.',
  'admin.employee.remindCvUpdate': 'Send a reminder to check and update profile',
  'admin.employee.toProfile': 'Go to users profile',
  'admin.employee.notifications': 'Set as organization contact person',
  'admin.employee.language': 'User interface language',
  'isDefaultCv.header': 'Profile management and upkeep',
  'isDefaultCv.unset': 'Employee itself',
  'isDefaultCv.set': 'Sales and Management',
  'isDefaultCv.unset.helperText':
    'By default sales and management do not have access to edit the employee profile. Employee manages their profile themselves.',
  'isDefaultCv.set.helperText':
    'Sales and management manages employee profile by default. Employee has also edit access to the profile.',
  sendActivation: 'Send activation email',
  'admin.title.employees': 'Employees',
  'admin.organization.site.streetAddress': 'Street address',
  'admin.organization.site.title': 'Sites',
  'admin.organization.site.postalCode': 'Postal code',
  'admin.organization.site.city': 'City',
  'admin.organization.site.countryCode': 'Country',
  'admin.organization.site.isHq': 'Is headquarters',
  'admin.organization.addTitle': 'Add Organization',
  'admin.organization.editTitle': 'Edit Organization',
  'admin.organization.countryLabel': 'Country',
  'admin.organization.description': 'Description',
  'admin.organization.exportColors': 'Export colors',
  'admin.organization.color': 'CV Banner color',
  'admin.organization.accentColor': 'CV Heading color',
  'admin.organization.name': 'Name',
  'admin.organization.companyIdentifier': 'Company identifier',
  'admin.organization.addSite': 'Add site',
  'admin.organization.personnel': 'Personnel',
  'admin.organization.turnover': 'Turnover',
  'admin.organization.address': 'Address',
  'admin.organization.contactPerson': 'Contact person',
  'admin.organization.contactPersons': 'Contact persons',
  'admin.organization.eachExpert': 'Each expert',
  'admin.organization.singleContact': 'Single point of contact',
  'admin.organization.eachExpertSingleContact': 'Each expert and single point of contact',
  'admin.organization.selectContact': 'Select contact',
  'admin.organization.networkShare':
    'When part of a network, share only percentage and duration of allocations. Information is shared only with networks that the organization is member of.',
  'admin.organization.parseCredits': 'Parsing credits',
  'admin.organization.defaultLanguage': 'Default user interface language',
  'admin.organization.caleoOwner': 'Caleo owner',
  'admin.organization.tenantId': 'TenantID for Azure AD login',
  'admin.organization.emailDomain': 'Email domain for Azure AD tenant',
  'admin.organization.logo': 'Logo',
  'admin.organization.exportSkillsExperience': 'Export skills and experience',
  'admin.organization.allowCreateNetworks': 'Allow network creation',
  'companyAdmin.organizationEdit.title': 'Company information',
  'admin.title.organizations': 'Organizations',
  'admin.title.userManual': 'User manual',
  'admin.title.widgets': 'Widgets',
  'admin.widget.table.name': 'Name',
  'admin.widget.table.type': 'Type',
  'admin.widget.table.url': 'URL',
  'admin.widget.organizationId': 'Organization',
  'admin.widget.name': 'Widget name',
  'admin.widget.type': 'Widget type',
  'admin.widget.description': 'Description',
  'admin.widget.showCode': 'Show code',
  'admin.widget.edit': 'Edit settings',
  'admin.widget.editTitle': 'Edit widget',
  'admin.widget.addTitle': 'Add new widget',
  'admin.widget.networks': 'Select search scope',
  'admin.widget.leadEmail': 'Lead email',
  'admin.widget.code': 'Widget code',
  'admin.widget.code.helperText': 'Add this code to your website to enable the widget:',
  'admin.widget.head': 'Add this script to <head> section',
  'admin.widget.body': 'Add this script to where the widget is wanted',
  'admin.widget.embeddedSearch': 'Embedded search',
  'admin.widget.somethingElse': 'Something else listed',
  'admin.widget.leadEmail.helperText': 'Email to receive leads from customers using the search.',
  'admin.widget.origin': 'URL where widget is used',
  'admin.organization.site.addTitle': 'Add Site',
  'admin.organization.site.editTitle': 'Edit Site',
  'admin.organization.site.countryLabel': 'Country',
  'admin.organization.downloadAllCVs': 'Download all CVs',
  'admin.organization.exportAllocations': 'Export organization allocation data',
  'admin.organization.featureset': 'Features',
  'admin.organization.contactPeople.info': 'Selected users will be notified when a new assignment is shared.',
  'admin.organization.organizationDetails': 'Organization details',
  'organizationExtraField.operationalControlSystem': 'Operational control system',
  'organizationExtraField.usedApplications': 'Used applications',
  'organizationExtraField.helpText': 'Write and press enter to add missing',
  'organizationExtraField.header': 'Organization extra fields',
  'admin.permission.groupType.admin': 'Admin',
  'admin.permission.groupType.inputDataManager': 'Input data manager',
  'admin.permission.groupType.companyAdmin': 'Company Admin',
  'admin.permission.groupType.sales': 'Sales',
  'admin.permission.groupType.freelancer': 'Freelancer',
  'admin.permission.groupType.recruit': 'Recruit',
  'admin.permission.groupType.teamLeader': 'Team leaders',
  'admin.permission.group.addTitle': 'Add Group',
  'admin.permission.group.editTitle': 'Edit Group',
  'admin.permission.group.name': 'Name',
  'admin.permission.group.description': 'Description',
  'admin.permission.memberName': 'Name',
  'admin.permission.removeMember': 'Remove member',
  'admin.permission.removeMemberConfirm': 'Are you sure you want to remove {{name}} from this group?',
  'admin.permission.groupDelete': 'Remove permission group',
  'admin.permission.removeGroupConfirm': 'Are you sure you want to remove {{name}} permission group from user?',
  'admin.permission.permissionCRUD': 'CRUD',
  'admin.permission.permissionObject': 'Object',
  'admin.permission.permissionType': 'Type',
  'admin.permission.editPermission': 'Edit permission',
  'admin.permission.removePermission': 'Revome permission',
  'admin.permission.group.title': 'Group',
  'admin.permission.group.membersTitle': 'Members',
  'admin.permission.addMember': 'Add member',
  'admin.permission.group.permissionsTitle': 'Permissions',
  'admin.permission.addPermission': 'Add permission',
  'admin.permission.user': 'User',
  'admin.permission.addMemberTitle': 'Add a member',
  'admin.permission.account': 'Account',
  'admin.permission.allocation': 'Allocation',
  'admin.permission.assignment': '',
  'admin.permission.cv': 'CV',
  'admin.permission.organization': 'Organization',
  'admin.permission.person': 'Person',
  'admin.permission.site': 'Site',
  'admin.permission.group': 'Group',
  'admin.permissions.type': 'Type',
  'admin.permission.network': 'Network',
  'admin.permission.article': 'Article',
  'admin.permission.addPermissionTitle': 'Add permission',
  'admin.permission.addPermission.create': 'Create',
  'admin.permission.addPermission.update': 'Update',
  'admin.permission.addPermission.read': 'Read',
  'admin.permission.addPermission.delete': 'Delete',
  'admin.permission.groupType': 'Type',
  'admin.permission.description': 'Description',
  'admin.permission.editGroup': 'Edit Group',
  'admin.permission.organizationId': 'Organization',
  'admin.permission.personId': 'Person',
  'admin.permission.group.organizationId': 'Organization',
  'admin.permission.other': 'Other',
  'admin.skills.name': 'Name',
  'admin.skills.rename': 'Rename',
  'admin.skills.nameTaken': 'Name is already in use. Please use another name or try merging instead.',
  'admin.skills.actions': 'Actions',
  'admin.skills.promote': 'Promote',
  'admin.skills.promote.confirmationText': 'Are you sure you want to promote skill?',
  'admin.skills.merge': 'Merge',
  'admin.skills.merging': 'Merging',
  'admin.skills.mergeTo': 'Merge to',
  'admin.skills.split': 'Split',
  'admin.skills.splitting': 'Splitting',
  'admin.skills.splitTo': 'Split to',
  'admin.skills.split.toLengthError': 'Select at least 2 skills to split to.',
  'admin.skills.delete': 'Delete',
  'admin.skills.languages': 'Languages',
  'admin.skills.promoteSelected': 'Promote selected',
  'admin.skills.mergeSelected': 'Merge selected',
  'admin.skills.deleteSelected': 'Delete selected',
  'admin.skills.delete.confirmationText':
    'This will also delete all related skills from all the listed person-skills, projects and assignments!',
  'admin.skills.showAll': 'Show all',
  'admin.skills.showNew': 'Show new',
  'admin.skills.showPromoted': 'Show promoted',
  'admin.skills.all': 'All',
  'admin.skills.new': 'New',
  'admin.skills.translate': 'Translate',
  'admin.skills.translate.fi': 'Finnish translation',
  'admin.skills.translate.eng': 'English translation',
  'admin.skill': 'Skill',
  'admin.industry': 'Industry',
  'admin.role': 'Role',
  'admin.title.permissions': 'Permissions',
  'admin.title.skills': 'Skills',
  'admin.title.industries': 'Industries',
  'admin.title.roles': 'Roles',
  'admin.title.dataManagement': 'Input data management',
  'admin.userManual.pageTitle': 'Title',
  'admin.userManual.url': 'Page address',
  'admin.userManual.modal.title': 'Title',
  'admin.userManual.modal.url': 'URL slug',
  'admin.userManual.modal.addTitle': 'Add user manual',
  'admin.userManual.modal.editTitle': 'Edit user manual',
  'admin.title.featureToggles': 'Feature toggles',
  'admin.feature.reports': 'Reports',
  'admin.feature.teams': 'Teams',
  'admin.feature.dashboard': 'Dashboard',
  'admin.feature.scout': 'Scout',
  'admin.feature.assignments': 'Assignments',
  'admin.feature.allocations': 'Allocations',
  'admin.feature.search': 'Search',
  'admin.feature.companyAdmin': 'Company admin',
  'admin.feature.profile': 'Profile',
  'admin.feature.networks': 'Networks',
  'admin.feature.widgets': 'Widgets',
  'admin.feature.allocationOverview': 'Allocation overview',
  'admin.feature.salespool': 'Sales pool',
  'admin.feature.parse': 'CV parsing',
  'admin.feature.chat': 'Chat',
  'admin.feature.apply': 'Apply top selection',
  'admin.title.active': 'Active users',
  'admin.activeStatistics.header': 'Statistics by date',
  'admin.activeStatistics.type': 'Type',
  'admin.activeStatistic.login': 'Login',
  'admin.activeStatistic.refresh': 'Refresh',
  'admin.activeStatistic.logout': 'Log out',
  'admin.title.auditlog': 'Auditlog',
  'admin.auditlog.userId': 'User ID',
  'admin.auditlog.action': 'Action',
  'admin.auditlog.actionData': 'Action data',
  'admin.auditlog.timestamp': 'Timestamp',
  'admin.auditlog.actionType': 'Action type',
  'admin.auditlog.all': 'All',
  'admin.auditlog.assignment': 'Assignment',
  'admin.auditlog.assignmentroleproposal': 'Assignment role proposal',
  'admin.auditlog.assignmentvisibility': 'Assignment visibility',
  'admin.auditlog.cv': 'CV',
  'admin.auditlog.allocation': 'Allocation',
  'admin.auditlog.team': 'Team',
  'admin.auditlog.account': 'Account',
  'admin.auditlog.person': 'Person',
  'admin.auditlog.group': 'Group',
  'admin.auditlog.network': 'Network',
  'admin.auditlog.organization': 'Organization',
  'admin.auditlog.search': 'Search',
  'admin.auditlog.proposalsearch': 'Proposal search',
  'admin.auditlog.searchproposal': 'Search proposal',
  'admin.auditlog.feature': 'Feature',
  'admin.auditlog.sendingemail': 'Sending email',
  'admin.auditlog.refreshtoken': 'Refresh token',
  'print.noCV': 'No CV to print!',
  'print.layout.title': 'Layout',
  'print.layout.addTitle': 'Add layout',
  'print.layout.closeAll': 'Close All',
  'print.layout.about': 'About',
  'print.layout.education': 'Education',
  'print.layout.workhistory': 'Work Experience',
  'print.layout.projects': 'Projects',
  'print.layout.references': 'Recommendations',
  'print.layout.skills': 'Skills',
  'print.layout.industries': 'Industries',
  'print.layout.roles': 'Roles',
  'print.layout.languages': 'Languages',
  'print.layout.degrees': 'Degrees',
  'print.layout.courses': 'Courses',
  'print.layout.certificates': 'Certificates',
  'print.layout.links': 'Links',
  'print.layout.name': 'Name',
  'print.layout.add': 'Add Layout',
  'print.layout.showContactInfo': 'Show contact information',
  'print.layout.useOwnCompanyBranding': 'Use own company branding',
  'print.reference.name': 'Name',
  'print.reference.company': 'Company',
  'print.reference.title': 'Recommendations',
  'print.reference.referee': 'Referee',
  'print.employment.roles': 'Roles',
  'print.project.role': 'Role',
  'print.project.customer': 'Customer',
  'print.project.employer': 'Employer',
  'print.project.skills': 'Skills',
  'print.project.industries': 'Industries',
  'print.project.roles': 'Roles',
  'print.skills.expert': 'Expert',
  'print.skills.advanced': 'Advanced',
  'print.skills.intermediate': 'Intermediate',
  'print.skills.novice': 'Novice',
  'print.skills.fundamentals': 'Fundamentals',
  'print.skills.noLevel': 'No level',
  'print.controls.layout': 'Select Layout:',
  'print.controls.addLayout': 'Add layout',
  'print.controls.deleteLayout': 'Delete Layout',
  'print.controls.selectCV': 'Select CV',
  'print.preview.title': 'Preview',
  'print.preview.pdf': 'PDF preview',
  'print.about.title': 'About',
  'print.education.title': 'Education',
  'print.degrees.title': 'Degrees',
  'print.courses.title': 'Courses',
  'print.certificates.title': 'Certificates',
  'print.education.licence': 'Licence number',
  'print.skills.title': 'Skills',
  'print.PersonSkills.title': 'Skills',
  'print.industries.title': 'Industries',
  'print.Industries.title': 'Industries',
  'print.roles.title': 'Roles',
  'print.Roles.title': 'Roles',
  'print.employments.title': 'Employments',
  'print.Employers.title': 'Employments',
  'print.projects.title': 'Projects',
  'print.languages.title': 'Languages',
  'print.visibilityOn': 'Hide',
  'print.visibilityOff': 'Show',
  'print.download': 'Download PDF',
  'print.downloadDocx': 'Download DOCX',
  'print.degree': 'Degree',
  'print.course': 'Course',
  'print.certificate': 'Certificate',
  'print.organizer': 'Organizer',
  'print.certifier': 'Certifier',
  'print.hideProfileImage': 'Hide profile image',
  'print.hideName': 'Hide name',
  'print.links.title': 'Links',
  'print.hideLastUsed': 'Hide skill last used',
  'print.showCertificateExpiry': 'Show certificate expiration date',
  'print.certificateExpiry': 'Expires',
  'print.url': 'URL',
  'tos.title': 'Terms of Service',
  loading: 'Loading',
  loadingMore: 'Loading more results...',
  'navigation.networks': 'Networks',
  'network.title': 'Networks',
  'network.name': 'Name',
  'network.owner': 'Owner',
  'network.brokers': 'Brokers',
  'network.managers': 'Managers',
  'network.members': 'Members',
  'network.cvCount': 'CV count',
  'network.joinRequests': 'Requests',
  'network.visibility': 'Visibility',
  'network.type.private': 'Private',
  'network.type.public': 'Public',
  'network.editTitle': 'Edit Network',
  'network.addTitle': 'Create Network',
  'network.type': 'Type',
  'network.description': 'Description',
  'network.charLeft': 'Characters left',
  'network.salesContactsVisible': 'Sales contacts visible',
  'network.NetworkBrokers': 'Brokers',
  'network.NetworkManagers': 'Network Member Managers',
  'network.addMemberOrganization': 'Add organization as member',
  'network.addMemberAccount': 'Add account as member',
  'network.add': 'Add',
  'network.pending': 'Pending',
  'network.confirmBack': 'Are you sure Want to go Back? All changes will be discarded!',
  'network.pending.title': 'Pending',
  'network.pending.name': 'Name',
  'network.pending.accept': 'Accept',
  'network.pending.deny': 'Deny',
  'network.shareCV': 'Share CV',
  'network.shareDialog': 'Share CV',
  'network.share.organizationId': 'Organization',
  'network.share.expiration': 'Expiration date',
  'network.unsubNotificationEmail': 'Emails for unsubcribe notifications (multiple addresses separated by semicolon)',
  'network.assignmentCopyEmail':
    'Send copies for networks shared assignments (multiple addresses separated by semicolon)',
  'network.source': 'Select a source network',
  'network.overwrite': 'Overwrite/populate',
  'network.manage.title': 'Manage network members',
  'network.manage.infoPage': 'Network benefits page',
  'network.infoPageVisible': 'Network benefits visible',
  'network.parserEnabled': 'CV parser',
  'network.chatEnabled': 'Chat feature',
  'editInfoPage.editTitle': 'Network benefits page',
  'editInfoPage.infoPage': 'Benefits page content',
  'network.manage.proposalInfo': 'Proposals info',
  'network.manage.details': 'Details edit',
  'network.manage.notifications': 'Notifications',
  'network.manage.remove': 'Remove from network',
  'network.manage.removeConfirm': 'Are you sure you want to remove {{name}} from network?',
  'network.manage.accountsWarning': 'Organization has accounts so it cannot be removed.',
  'network.notification.salesOnly': 'Send to sales people only',
  'network.notification.skillBased': 'Send only to people with skills',
  'network.notification.skills': 'Skills',
  'network.notification.headerfi': 'Notification header fi',
  'network.notification.headeren': 'Notification header en',
  'network.notification.messagefi': 'Message fi',
  'network.notification.messageen': 'Message en',
  'network.deleteTitle': 'Are you sure you want to delete {{networkName}} network?',
  'network.onlyBrokerShare': 'Only brokers can share assignments',
  'editProposalInfo.editTitle': 'Proposal info',
  'editProposalInfo.proposalGuide': 'Proposal info content',
  'navigation.benefits': 'Network benefits',
  'dashboard.title': 'Dashboard',
  'dashboard.quickAssignments': 'Show quick assignments',
  'dashboard.information': 'Information',
  'dashboard.proposed': 'Proposed to assignments (not verified)',
  'dashboard.allocated': 'Allocated to assignments',
  'dashboard.available': 'Available employees',
  'dashboard.becomingAvailable': 'Available in',
  'dashboard.name': 'Name',
  'dashboard.assignment': 'Assignment',
  'dashboard.startDate': 'Start date',
  'dashboard.assignmentRole': 'Role',
  'dashboard.skills': 'Skills',
  'dashboard.location': 'Location',
  'dashboard.lastAllocation': 'Previous assignment',
  'dashboard.weeks': 'weeks',
  'dashboard.months': 'months',
  'dashboard.toProfile': 'To profile',
  'dashboard.viewProfile': 'View profile',
  'dashboard.toAssignment': 'Assignment',
  'dashboard.ownAssignments': 'Show only own',
  'dashboard.ownAssignmentsTooltip': 'Show only assignments, which are assigned to me',
  'network.visibility.title': 'Networks assignment visibilities',
  'network.acceptVisibilities': 'Accept assignment visibilities',
  'network.assignment': 'Assignment',
  'network.visibility.status': 'Status',
  'network.visibility.accepted': 'Accepted',
  'network.visibility.notAccepted': 'Not accepted',
  'network.visibility.acceptedAt': 'Date of aceptance',
  'network.assignmentsVisible': 'Assignments visible',
  'network.teamsVisible': 'Teams visible',
  'network.pricesVisible': 'Prices visible',
  'network.statistics': 'Network statistics',
  'network.statistics.showFreelancers': 'Show freelancers',
  'network.statistics.unsubscribedMembers': 'Unsubscribed',
  'network.statistics.location': 'Location',
  'network.statistics.showOrganizations': 'Show organizations',
  'network.statistics.contactPersons': 'Contact persons',
  'network.statistics.memberName': 'Member name',
  'network.statistics.visibleUserCount': 'Visible user count',
  'network.statistics.totalUserCount': 'Total user count',
  'network.statistics.lastLoggedIn': 'Last login',
  'network.statistics.freelancerCount': 'Freelancer count:',
  'network.statistics.organizationCount': 'Organization count:',
  'network.statistics.activeOrganizations': 'Active organizations:',
  'network.statistics.nonActiveOrganizations': 'Non-active organizations:',
  'network.statistics.activeOrganizationRatio': 'Active organization ratio:',
  'network.statistics.activeUsers': 'Active users:',
  'network.statistics.nonActiveUsers': 'Non-active users:',
  'network.statistics.activeUsersRatio': 'Active user ratio:',
  'network.statistics.activeFreelancers': 'Active freelancers:',
  'network.statistics.nonActiveFreelancers': 'Non-active freelancers:',
  'network.statistics.activefreelancersRatio': 'Active freelancer ratio:',
  'network.statistics.export.contacts': 'Export network contacts',
  'network.statistics.export.include': 'Include',
  'network.statistics.export.freelancers': 'Freelancers',
  'network.statistics.export.organizations': 'Organizations',
  'network.statistics.export': 'Export',
  'assignment.modal.AssignmentVisibilities': 'Share to networks',
  'scoutFAQ.title': 'Scout introduction',
  'scoutFAQ.introduction': `Scout is a questionnaire tool for individuals, teams and companies to understand their personnel values better based on C.G Jung's research. The questionnaire is done once.`,
  'scoutFAQ.question1': 'How are scout results used?',
  'scoutFAQ.question2': 'How to read and interpret Scout results?',
  'scoutFAQ.question3': 'Who can see my results?',
  'scoutFAQ.answer1':
    'Scout results are used for internal reporting purposes. The results are used to share information internally, within the company to better understand team composition, company structure and the soft values of the company personnel. \n \n In the future the scout results might be added to the search function of Caleo.',
  'scoutFAQ.answer2':
    'The results are not to be taken as absolute truth. The results matching accuracy should be reflected and assessed individually after submitting the questionnaire.',
  'scoutFAQ.answer3':
    'Your team lead and the company management has the access to your end results. Only the summary is shared and not the responses for individual questions.',
  'scoutPlus.guide':
    'The scan comprises 18 different sets of arguments with four choices.\n' +
    'For each option the person conducting the scan evaluates his or her own view of the action and/or thinking in question with points 6, 4, 3 and 1.\n' +
    'The evaluator gives six (6) points out of the four options that he/she considers best to describe himself/herself in different working life situations. Four points to the option describing himself/herself the second best, then three (3) points, and one (1) point for the least self-depicting option.',
  'scoutPlus.welcome': 'Scout-Plus Guide',
  'scoutPlus.start': 'Start Scout-Plus',
  'scoutPlus.results': 'Scout-Plus results',
  'scoutPlus.question1': 'Others probably see me as',
  'scoutPlus.question2': 'When working on a task',
  'scoutPlus.question3': 'When I talk to others, I might',
  'scoutPlus.question4': 'When, for some reason, I can’t do what I want, I think it’s best to',
  'scoutPlus.question5': 'Sometimes I think others might see me as',
  'scoutPlus.question6': 'When I write business mail to someone I don’t know, I usually try',
  'scoutPlus.question7': 'When others disagree with me, I’m usually able to move forward',
  'scoutPlus.question8': 'When I look at things in terms of the time dimension, I will probably focus the most',
  'scoutPlus.question9': 'In my relationship with the people I meet in my spare time, I will probably consider',
  'scoutPlus.question10':
    'When I talk to people I haven’t been in regular contact with, I would like to leave a picture of myself',
  'scoutPlus.question11': 'In tough negotiation situations, I might sometimes',
  'scoutPlus.question12': 'If I’m not careful, others may at times feel like I am',
  'scoutPlus.question13': 'I am happy with myself when',
  'scoutPlus.question14': 'I find it easy to be convincing when',
  'scoutPlus.question15': 'I enjoy when others think of me as',
  'scoutPlus.question16': 'When I am in a difficult situation, I think it’s wiser to',
  'scoutPlus.question17': 'When others put pressure on me, I am',
  'scoutPlus.question18':
    'When I think about my approach to difficult situations, it is possible that I become too tangled',
  'scoutPlus.answer1a': 'practical and objective',
  'scoutPlus.answer1b': 'emotional and uplifting',
  'scoutPlus.answer1c': 'sharp and consistent',
  'scoutPlus.answer1d': 'intelligent and a bit impalpable',
  'scoutPlus.answer2a': 'I want the task to be stimulating and involve a lot of interaction with others',
  'scoutPlus.answer2b': 'I focus on ensuring that the task is systematically and logically planned',
  'scoutPlus.answer2c':
    'I want to make sure that the task has tangible benefits so that I should spend time and energy on it',
  'scoutPlus.answer2d': 'I am mainly interested in whether the task is “new creative” or whether it promotes knowledge',
  'scoutPlus.answer3a': 'inadvertently express my boredom to a conversation that is too detailed',
  'scoutPlus.answer3b':
    'shows impatience to those who present ideas that have apparently not been sufficiently considered',
  'scoutPlus.answer3c': 'show a diligent interest in thoughts and ideas that lack originality',
  'scoutPlus.answer3d':
    'easily overlook those who are dealing with long-term effects and draw attention to things that should be done right now',
  'scoutPlus.answer4a': 'look at any shortcomings or weaknesses in my way of thinking and make changes accordingly',
  'scoutPlus.answer4b': 'rethink everything that has happened and develop a new default model',
  'scoutPlus.answer4c':
    'keep the basics in mind, identify the main obstacles and modify the “game plan” to take these factors into account;',
  'scoutPlus.answer4d': 'Analyze the motivations of others and develop a new touch for the issue',
  'scoutPlus.answer5a': 'too emotional or even passionate',
  'scoutPlus.answer5b': 'too reserved and too logical',
  'scoutPlus.answer5c': 'too interested in details and “how” questions?',
  'scoutPlus.answer5d': 'as a person with a lot of ideas and a little hard to understand',
  'scoutPlus.answer6a': 'to find out the reasons behind the contact and attach them to why I am contacting them now',
  'scoutPlus.answer6b': 'to say in simple words what I want, need or expect from this person',
  'scoutPlus.answer6c': 'to show how the main issues I present relate to the wider picture',
  'scoutPlus.answer6d': 'to give at least some sort of “sample” of myself and my style',
  'scoutPlus.answer7a': 'by gaining a commitment to at least a couple of points on which we can later build',
  'scoutPlus.answer7b': ' by seeking to look at things from the perspective of others',
  'scoutPlus.answer7c': 'by maintaining my composure and helping others see things simply and consistently',
  'scoutPlus.answer7d': 'by relying on my ability to perceive and connect things together',
  'scoutPlus.answer8a': 'on whether what I do will be remembered as a relevant thing',
  'scoutPlus.answer8b': 'to ensure that all actions I take are consistent and a part of a systematic system',
  'scoutPlus.answer8c': 'on immediate measures and events, and their significance at the moment',
  'scoutPlus.answer8d':
    'on the significant long-term measures I intend to take and how they relate to the life course in general;',
  'scoutPlus.answer9a': 'whether they can give ideas and challenges',
  'scoutPlus.answer9b': 'whether they look thoughtful and contemplative',
  'scoutPlus.answer9c': 'whether they are interesting and whether it is fun to be with them',
  'scoutPlus.answer9d': 'whether they know what they are doing and can they accomplish something',
  'scoutPlus.answer10a': 'as a systematic thinker who is able to analyze the problems that this group has to solve',
  'scoutPlus.answer10b': 'as a wide-ranging thinker who is able to find new solutions',
  'scoutPlus.answer10c':
    'as an experienced and resourceful person who can help the group identify concerns and who can help them solve problems',
  'scoutPlus.answer10d': 'as a lively and influential person who understands their needs and moods',
  'scoutPlus.answer11a':
    'express my thoughts too freely and to express emotionally things that might have been better left unsaid',
  'scoutPlus.answer11b': 'be too careful and avoid connections that might have proven rewarding',
  'scoutPlus.answer11c': 'to lose "the forest for the trees,” to get stuck in secondary things',
  'scoutPlus.answer11d': 'be guided by people who are talented but may lack a clear goal',
  'scoutPlus.answer12a': 'very insensitive and prone to be distant and impersonal',
  'scoutPlus.answer12b': 'superficial or self-centered drudge',
  'scoutPlus.answer12c': 'a little snobbish, intellectually superior or demeaning',
  'scoutPlus.answer12d': 'capricious, easily provoked or unpredictable',
  'scoutPlus.answer13a': 'I get more things done than I had planned',
  'scoutPlus.answer13b': 'I understand the feelings of others and I can help them',
  'scoutPlus.answer13c': 'I solve a problem using logical or systematic methods',
  'scoutPlus.answer13d': 'I develop new thoughts and ideas that can be combined with each other',
  'scoutPlus.answer14a': 'I am up to date with my own feelings and I also know the feelings of others',
  'scoutPlus.answer14b': 'I am consistent, patient and resilient',
  'scoutPlus.answer14c': 'I am factual and pragmatic',
  'scoutPlus.answer14d': 'I am intellectually up to date and able to take into account all influencing factors',
  'scoutPlus.answer15a': 'intellectually talented and open-minded',
  'scoutPlus.answer15b': 'a person who knows where he/she is going and is qualified to achieve his/her goals',
  'scoutPlus.answer15c': 'creative and stimulating',
  'scoutPlus.answer15d': 'as a reliable person who gets things done and who moves forward',
  'scoutPlus.answer16a':
    'adhere to a systematic method that has proven to be effective in the past, although taking more risks could lead to more profits',
  'scoutPlus.answer16b': 'to be considered original, even if it would cost me in the short term',
  'scoutPlus.answer16c':
    'to focus on what I want to get done right now, even if doing so would not bring about anything great',
  'scoutPlus.answer16d': 'be spontaneous and say what you really think',
  'scoutPlus.answer17a': 'hypersensitive, impulsive or I have to be easily carried away by emotions',
  'scoutPlus.answer17b': 'too inclined to look at them analytically and critically',
  'scoutPlus.answer17c': 'too sensitive to prove my opinion by direct action',
  'scoutPlus.answer17d': 'inclined to retreat into my own world of thought',
  'scoutPlus.answer18a': 'to the struggles of reason and the solution of the problem, even for their own sake',
  'scoutPlus.answer18b': 'to the immediate “here and now” situation where I act as I wish',
  'scoutPlus.answer18c': 'into a world of concepts, values and ideas',
  'scoutPlus.answer18d': 'to the feelings of others',
  'scoutPlus.paiResults': 'PAI-Score',
  'scoutPlus.norResults': 'NOR-Score',
  'scoutPlus.norResults.description': 'Normal environment',
  'scoutPlus.paiResults.description': 'Pressured environment',
  'scoutPlus.result.low': 'Low',
  'scoutPlus.result.veryLow': 'Very low',
  'scoutPlus.result.mid': 'Mid',
  'scoutPlus.result.high': 'High',
  'scoutPlus.result.veryHigh': 'Very high',
  'scoutPlus.opportunities': 'Opportunities',
  'scoutPlus.risks': 'Risks',
  'scoutPlus.resultPossibilitiesText': 'Results may vary in betweeen 9-54 points.',
  'scoutPlus.norScore': 'NOR-Score is ',
  'scoutPlus.paiScore': 'PAI-Score is ',
  'scoutPlus.veryHighScoreText':
    'With a very high score, opportunities and risks are strongly present and reflect important values.',
  'scoutPlus.highScoreText': 'With high result opportunities and risks apply.',
  'scoutPlus.midScoreText': 'With mid result opportunities and risks are noticed.',
  'scoutPlus.lowScoreText': 'With low result opportunities and risks are very little.',
  'scoutPlus.veryLowScoreText': 'With low result opportunities and risks are nonexistent.',
  'scoutPlus.normal': 'Normal',
  'scoutPlus.presured': 'Pressured',
  'scout.affectiveness.pros':
    'spontaneous involvement\n' +
    'the ability to listen profoundly when needed\n' +
    'honors traditions\n' +
    'senses the logic of people’s mood\n' +
    'identify one’s own strengths and weaknesses as well\n' +
    'skilled at getting others to express their feelings as well\n' +
    'an interactive, natural performer',
  'scout.affectiveness.cons':
    'over-impulsive by situations\n' +
    'able to manipulate\n' +
    'sensitive to critical feedback\n' +
    'emotional bursts take over\n' +
    'eager to postpone things\n' +
    'blame oneself a bit too often\n' +
    'worries "everything”',
  'scout.affectiveness.conclusion': `Appears in everyday life as an understanding person, a shoulder to cry on, a listener,
   at its worst dwells on human relationships.`,
  'scout.pragmatism.pros':
    'feet “firmly on the ground” in matters\n' +
    'a confident grip on everything to do\n' +
    'result-oriented in everything he/she does\n' +
    'does not believe before he/she sees\n' +
    'direct behavior, does not play games\n' +
    'competitive in a positive sense\n' +
    'self-confidence in place',
  'scout.pragmatism.cons':
    'thinks only of this day\n' +
    'seeks appreciation unhealthily\n' +
    'self-centered, loves himself/herself\n' +
    'works first, then thinks\n' +
    'does not trust the skills of others\n' +
    'dominant towards fellow human beings\n' +
    'the need to be right is strong',
  'scout.pragmatism.conclusion': `Appears in practical everyday life as fast, striving for concrete goals and also
   achievable but compromising on quality.`,
  'scout.pervasiveness.pros':
    'original, creative ideas\n' +
    'imagination possessed strongly\n' +
    'long-term creativity to think\n' +
    'great instinct for problems\n' +
    'exudes charismatic radiation\n' +
    'commits to what he/she admires\n' +
    'equipped with creative sparkling intelligence\n' +
    'commits to an ideological direction which approves',
  'scout.pervasiveness.cons':
    'feet "off the ground, floating"\n' +
    'mentally distant, difficult to reach\n' +
    'live in ones own "spheres"\n' +
    'things “at rest,” things open all the time\n' +
    'complex commitments\n' +
    'dreams also of the unreal\n' +
    'dogmatic, i.e. mentally rigid\n' +
    'impractical in their doings',
  'scout.pervasiveness.conclusion':
    'Appears in everyday life as a problem solver, a seeker of new perspectives, a visionary, easily frustrated and distant, intelligent person.',
  'scout.prudence.pros':
    'communicates in a quality and matter-of-fact manner\n' +
    'consistent as a negotiator\n' +
    'contemplative way of working\n' +
    'able to compare of different options\n' +
    'computational reason\n' +
    'objective in their attitude\n' +
    'skillful in key figures skillful, loves them',
  'scout.prudence.cons':
    'undecided on important matters\n' +
    'slows down negotiations too much\n' +
    'overemphasized analyticity\n' +
    'insensitive, things above\n' +
    'caution striking\n' +
    'reserved in human relationships\n' +
    'too issue-based, rigid',
  'scout.prudence.conclusion':
    'Appears in practical everyday life as a profound thinker, logician, quality maker, yet time consuming.',
  'scout.guide':
    'The mapping comprises 10 different sets of claims with four choices.\n' +
    'For each choice, the person carrying out the survey evaluates his/her own view of actions and/or thinking in situations in points 6, 4, 3 and 1.\n' +
    'The assessor gives six (6) points out of the four options that he/she considers best to describe himself/herself in different working situations. For the next best four (4) points, then three (3), and finally for the least self-depicting one (1) point.\n' +
    'Thus, on the basis of the self-analysis to be mapped, this creates an "own image" with a range of points of min 10 points and max 60 points. The ratio between the four regions is the basis for the evaluation.',
  'scout.finish': 'Finish',
  'scout.next': 'Next',
  'scout.welcome': 'R-Scout Guide',
  'scout.start': 'Start R-Scout',
  'scout.results': 'R-Scout results',
  'scout.showDetails': 'Show details',
  'scout.hideDetails': 'Hide details',
  'scout.questions': 'Questions',
  'scout.affectiveness': 'Affectiveness',
  'scout.prudence': 'Prudence',
  'scout.pragmatism': 'Pragmatism',
  'scout.pervasiveness': 'Pervasiveness',
  'scout.affectiveness.short': 'AFF',
  'scout.prudence.short': 'PRU',
  'scout.pragmatism.short': 'PRA',
  'scout.pervasiveness.short': 'PER',
  'scout.total': 'Total: ',
  'scout.question1': 'Good co-workers are important because',
  'scout.answer1a': 'it is nice and relaxed to be with them',
  'scout.answer1b': 'they can be accompanied by an in-depth discussion',
  'scout.answer1c': 'they have a secure and independent grip on what to do',
  'scout.answer1d': 'with them you learn to realize something new',
  'scout.question2': 'When I am at work',
  'scout.answer2a': 'I take other co-workers into consideration',
  'scout.answer2b': 'I think before I say my opinion',
  'scout.answer2c': 'I look at things from a practical point of view',
  'scout.answer2d': 'I produce new ideas effortlessly',
  'scout.question3': 'When I am at work',
  'scout.answer3a': 'I participate actively and get excited easily',
  'scout.answer3b': 'I act objectively and purposefully',
  'scout.answer3c': 'I can easily get practical results',
  'scout.answer3d': 'my intellectual skills help me with even wide-ranging problems',
  'scout.question4': 'In difficult situations at work',
  'scout.answer4a': 'I react immediately and inspire others to find out situations in question',
  'scout.answer4b': 'my criticism produces high-quality results',
  'scout.answer4c': 'I sense things immediately and act quickly',
  'scout.answer4d': 'I want to retreat for a moment to think more broadly about the situation in question',
  'scout.question5': 'While I am at work, I am considered as',
  'scout.answer5a': 'rich in ideas and motivated',
  'scout.answer5b': 'goal-oriented and professional',
  'scout.answer5c': 'reliable and gutsy',
  'scout.answer5d': 'open-minded and reformist',
  'scout.question6': 'When I talk to others at work, I appreciate',
  'scout.answer6a': 'new fresh ideas coming from them',
  'scout.answer6b': 'carefully considered views from them',
  'scout.answer6c': 'in particular the presentation of details',
  'scout.answer6d': 'original personal views',
  'scout.question7': 'When I am at work, I send out',
  'scout.answer7a': 'sensibility',
  'scout.answer7b': 'consistency',
  'scout.answer7c': 'practicality',
  'scout.answer7d': 'extensivity',
  'scout.question8': 'In distressing situations at work',
  'scout.answer8a': 'I express my opinion openly',
  'scout.answer8b': 'I am wisely careful and contemplative',
  'scout.answer8c': 'I decipher the situation using humor',
  'scout.answer8d': 'I see several solution options on situations in question',
  'scout.question9': 'I enjoy work that is',
  'scout.answer9a': 'humane',
  'scout.answer9b': 'systematic',
  'scout.answer9c': 'producing visible achievements',
  'scout.answer9d': 'challenging and stimulating',
  'scout.question10': 'When I am at work, my strength is',
  'scout.answer10a': 'persuasiveness',
  'scout.answer10b': 'deliberation',
  'scout.answer10c': 'speed',
  'scout.answer10d': 'ability to see into the future',
  'scout.result.aaaa':
    ' is to a large extent a good listener and interactor, is good at recognizing the emotional states of others, motivator and inspires others to join in. Is consistent, combines reason and feeling , sensory and human sensitive. Productive also in practice, self-confidence in place, resultative, creative, persistent, committed. The risks are over-eagerness, willingness to manipulate, sensitive to react to negative feedback, excessive self-centeredness, dominance, mental rigidity',
  'scout.result.baaa':
    ' is to a large extent a common-sense person, good communicator, appreciative of concrete, soundly confident, results-oriented, both charismatic and committed. The risks are that the person is insensitive, case centered, to some extent incomprehensible to people. To some extent, he is interactiven, at least in measurable cases.',
  'scout.result.caaa':
    ' is largely a good communicator, negotiable, objective, productive, believes in concreteness, self-confident, comprehensive, committed.  Very limited interpersonal skills, the ability to settle in others positions is lacking, hypersensitive to negative feedback, tends to postpone things.',
  'scout.result.abab':
    ' is highly emotional, empathic, good listener, motivator. The risk of over-impulsivity, short-sightedness, willingness to manipulate. To some extent is consistent, objective, to a very large extent concretely productive and straight-forward, risk of excessive speed in action, to some extent a feedback provider, creative, pervasive and committed.',
  'scout.result.baba':
    ' is to some extent an emotional and understands people, even empathic. To a very large extent prudent, case centered when needed, the risk of certain degree of caution and indecisiveness.  To some extent also an implementer, confident, productive. To a very large extent creative, original, committed, intelligent, at the risk of mental rigidity and complexity in expressions.',
  'scout.result.abba':
    ' is highly interactive, empathic, listener of people. The risk of criticality for negative feedback, blaming oneself and postponing things. Somewhat thoughtful and logical, why not long-term as well. Is somewhat result-oriented, able and willing to compete.  To a large extent is innovative, creative, visionary, intelligent. The risk of leaving tasks unfinished, being "feet off the ground" as well as being mentally rigid',
  'scout.result.baab':
    ' is somewhat emotional towards people, listener, empathic too, largely prudent, persistent, analytic, the risk of excessive objectivity, "meter"emphasis.  To a large extent also inducing, also fast, at the risk of self-centeredness, distrust to others, the need to be right.  Is intuitive to some extent, future-oriented, rational.',
  'scout.result.acba':
    ' is to a large extent an interpersonal expert, good listener, empathic, risk of short-sightedness and excessive subjectivity.  Is prudent to a very limited extent, skilled in negotiating, key figure expert. Somewhat practical, even fast when necessary, achiever. Largely extensive, even charismatic, committed, risk of dreaminess, impracticality and complexity.',
  'scout.result.abca':
    ' is emotional to a large extent, empathic sympatizer of people, profound listener, in the risk of emotion churning as well as non-accomplishment. To some extent is thoughtful and skilled in negotiating, even objective and analytic. To a very limited extent a hands-on performer, a self-confident results-oriented type. Has intuitiveness to a large extent, visionary skills as well as creativity, the risk of distance from fellow human beings mentally and mental stiffness.',
  'scout.result.bcab':
    ' is a somewhat empathetic listener, responsive to people, even a motivator, very little prudent, analytic and persistent. Largely practical, even fast in their doing, gives out self-confidence , the risk of the negligence of others and dominance. Somewhat extensive, committed and intelligent.',
  'scout.result.bacb':
    ' is somewhat emotional, understanding towards people, even empathic, largely prudent, case centered, analytic, risk of 110% quality aspiration. To a very limited extent a results-oriented fast performer, not mentally powerful. To some extent, however, extensive, case sensitive, smart as well as committed',
  'scout.result.aabb':
    ' is largely an emotional person, empathic, motivator, risk of manipulation, sensitivity to critical feedback. To a large extent also considerating, creator of quality, a balancer of reason and emotion, at the risk of turmoil of sense and sensibility, indecision, overemphasized analyticality. To some extent the person is also pragmatic, results are also generated in concrete terms, a straightforward person, even wide-ranging, sensitive, even intuitive.',
  'scout.result.bbac':
    ' is to some extent an emotional person, even empathetic, listener, likes positive feedback, to some extent also quality-providing, reflector, even persistent. To a large extent a result maker and even fast, practical, the risk of self-centeredness and excessive straightforwardness.  To a very limited extent extensive, case sensitive, synthesizer, original, creative.',
  'scout.result.baca':
    ' is to some extent an understanding, empathetic, listener, understands the feelings of others.  Largely thoughtful, persistent, consistent quality creator, risk of caution in decision making, mental distance.  To a very limited extent practical, effective, concrete, feet on the ground. Largely broad-based, intuitive, case-sensitive, creative, risk of mental distance and inwardness.',
  'scout.result.caba':
    ' is to a very limited extent sensitive, does not really understand people, does not listen to body language.  To a large extent is logical, case centered, negotiative, risk of indecision and reserved. To some extent is pragmatic, even accomplishing, confident, largely original, creative, instinctive, case senstive, at the risk of being mentally distant, dogmatic',
  'scout.result.bbaa': ` is somewhat emotional, understanding  and an empathic listener, to some extent also a thoughtful, persistent, comparator of alternatives. Largely pragmatic, executor, results-oriented, fast, at the risk of self-centeredness, acts first, then thinks, dominant. Largely extensive, visionary, instinctive, intelligent, at risk for mental rigidity, complexity in some thought patterns`,
  'scout.result.aaab':
    ' is somewhat emotional, understanding, good listener, empathic, at risk of lack of long-term creativity, lack of originality.  To a large extent also a thoughtful common sense person, a comparator of alternatives, at the risk of turmoil of sense and sensibility, willingness to manipulate negotiation skills. To a large extent also practical,  productive, the implementer of quality, the risk of dominance and self-centeredness.  To some extent is wide-ranging, even creatively intelligent, even visionary.',
  'scout.result.bcba':
    ' is somewhat emotional, understanding, why not even a reasonable listener, an interaction expert. To a very limited extent a contemplator, persistent quality creator, negotiable, logical, to some extent practical, results-oriented, even social. Very largely extensive, visionary, an intellectually creative, original, solver of extensive problems, the risk of daydreaming, mental stiffness, being feet off the ground.',
  'scout.result.abac':
    ' is somewhat emotional, understanding, empathic, good listener the risk of daydreaming, mental stiffness, being feet off the ground, self-blame, sensitivity to critical feedback. To some extent is thoughtful, even negotiable, even a common sense person, a communicator. To a large extent is pragmatic, productive, prompt grip, independent, risks at making quality, dominance, narrowness in opinions. To a very limited extent is extensive, persisently creative, imaginative, problem solver.',
  'scout.result.baac':
    ' is somewhat emotional, even empathic and a listener, even a motivator, largely thoughtful, persistent, analytical, common-sense, risk of pressure of achieving results internally. He is also largely pragmatic, productive, confident, at the risk of excessive self-centeredness and dominance.  To a very limited extent a wide-ranging synthesizer, visionary, creative.',
  'scout.result.aacb':
    ' is somewhat emotional, empathic, stands in the position of another person, risk of being wedged in turmoil of sense and sensibility, inefficiency.  He is largely thoughtful, persistent, analytical, comparator of different options, risk of wariness, slowness in decision making.  To a very limited extent is effective, productive, fast, dominant. To some extent is instinctive, even creative, even original.',
  'scout.result.babc': ` is somewhat emotional, understanding, even listener, senses people's mood. To a large extent thinker, analytical, quality creator, comparator of alternatives, risk of wariness, indecision, extent delving into figures. Somewhat practical, even productive in certain situations, independent, self-confidence can also be found. To a very limited extent he is wide-ranging, visionary, creative, intellectually tenacious.`,
  'scout.result.aabc':
    ' is to a large extent emotional, understanding, placing oneself in other persons position, good listener, at risk of sensitivity to critical feedback, postponing things. Is also a highly thoughtful, analytical, common sense person, striving for quality, persistent, at the risk on turmoil of sense and sensibility, excessive wariness, even dominance. Somewhat practical, even productive, feet firmly on the ground -person, social. To a very limited extent wide-ranging, visionary, persistently creative, original.',
  'scout.result.cbba':
    ' is to a very limited extent emotional regarding people, empathic, skilled in communication.  Somewhat thoughtful, even persistent, profound, analytic, to some extent also productive, independent, dominant, case-oriented. Largely wide-ranging, visionary, persistently creative, committed, solver of extensive problems, at the risk of mental distancing, dogmatism, complexity.',
  'scout.result.aaca': ` is to a large extent emotional, understanding, placing oneself in other persons position, atmosphere recognizer, at the risk of extent blending of sense and sensibility, blaming oneself, postponing things, to a large extent also a thinker, persistent, analytical comparator of different options, risk of wariness, indecision, being reserved. To a very limited extent a concrete doer, productive, fast, independent. Largely extensive, creative, committed, original, intellectual visionary, at the risk of mental distance, being in one's own spheres, dogmatism.`,
  'scout.result.abbc':
    ' is to a large extent emotional understanding, empathic listener, atmosphere sentient, risk of excessive sensitivity to people, sensitivity to critical feedback, postponing things. somewhat objective, persistent, even negotiable, also somewhat pragmatic, even yielding results, even confident, quick. To a very limited extent wide-ranging, persistently creative, visionary, instinctive.',
  'scout.result.bbab':
    ' is somewhat emotional regarding people, interpreter of human emotion, even motivator, listener. To some extent also prudent, analytical, even objective. Largely hands-on performer, productive, prompt, independent, result-maker, at risk of hig quality performance, consideration of other people, dominance, stubbornness. Is to some extent wide-ranging, even creative, even visionary, original.',
  'scout.result.acab':
    ' is to a large extent emotional observer of emotions of others, motivator, mental supporter, atmosphere sentient, at the risk of emotion-based way of working, lack of reason, making decisions on an emotional basis. Is to a very limited extent a thinker, persistent, analytic, comparator of different options. To a large extent is a doer, striving for concreteness, confident, prompt, result maker, at the risk of making emotion-based decisions, self-centeredness, trust in the skills of others. To some extent wide-ranging, visionary, committed, even creative.',
  'scout.result.bbba':
    ' is somewhat emotionally sensitive, understanding, even empathic, atmosphere sentient, even motivator. To some extent also a considerer, even a persistent, common-sense person, a comparator of different alternatives, somewhat pragmatic, even results-oriented, performance-oriented.  Largely wide-ranging, visionary, solver of wide problems, intellectually tenacious, risk of dogmatism, mental distance, complexity.',
  'scout.result.aaac':
    ' is to a large extent emotional, empathic, recognizing emotional states of others, motiva, atmosphere sentient, risk of sensitivity to critical feedback, postponing things, feelings of guilt. Also largely considerating, logical, persistent, analytic, risk of indecision and excessive reflection. Largely pragmatic, accomplishing on the basis of emotion and reason, result-oriented in their thinking, risk of the need of being right and dominance. To a very limited extent wide-ranging, case-sensitive, solver of broad problems, synthesis maker.',
  'scout.result.cbaa':
    ' is to a very limited extent emotional, empathic, atmosphere sentient, interactive, somewhat thoughtful, persistent, rational, even logical. Largely pragmatic, feet on the ground, confident, results-oriented, risk of self-centeredness, criticism of others, in need to be right. To a large extent also wide-ranging, visionary, instinctive, luova, risk of mental distance, sensitivity to things instead of sensitivity to humans.',
  'scout.result.caab':
    ' is to a very limited extent emotional, understanding, empathc, listener, atmosphere sentient. Is to a large extent a thinker, rational, logical, analytic, risk of excessive case-centeredness, insensitivity. To a large extent also a pragmatic implementer, independent, productive, quick in their grips, risk of results first, person then -type thinking, emotional diminution. Somewhat sensitive, intuitive, visionary, synthesizing, creative.',
  'scout.result.abaa':
    ' is to a large extent emotional and understanding, empathic listener, atmosphere sentient, motivator, risk of lack of use of reason, emotional bursts, manipulation skills. To some extent is considerate, logical, analytic, even objective, negotiable. Largely practical, results-oriented, prompt, even social, at the risk of compromising on quality, self-centeredness, dominance. To a large extent also wide-ranging, visionary, case-sensitive, instinctive, risk of rising to one’s own spheres, dogmatism, complexity.',
  'scout.result.acaa':
    ' is to a large extent emotional, understanding, motivator, good listener, empathic, risk of emotional superiority over reason, subjectivity and sensitivity to critical feedback. To a very limited extent a prudent thinker, analytic, common-sense person, logical. Largely practical, fast, results-oriented, confident, self-confidence in place, risk of emotion-based doing, quality suffers, dominance.  To a large extent also extensive, creative, visionary, synthesis maker, case sensitive, risk of dogmatism, mental distance.',
  'scout.result.abbb':
    ' is to a large extent a good listener and interactor, good to identify emotional states of others, motivator and inspires others to join in. Risk uf manipulation, sensitivity to negative feedback, short-sightedness, hypersensitivity. To some extent case-centered, reasonable logic, objectivity can be found, to some extent gauge-loving, somewhat result-oriented, confident. Also somewhat charismatic, committed, creativity can also be found.',
  'scout.result.abcb':
    ' is to a large extent skilled in interaction, an understanding person, empathic, atmosphere interpreter, at the risk of mixing the emotional and rational worlds, self-blame, postponing things, to some extent also intrusive, profound, even analytic. To some extent also thoughtful, logical, to a very limited extent a practical person, however, more theoretical, more inward-looking, not dominant. He is somewhat extensive, even creative, looking things from a distance, enjoying oneself on their own.',
  'scout.result.acbb':
    ' is to a large extent skilled in interaction, an understanding person, reader of expressions and gestures, motivator, risk of short-sightedness, blaming, manipulation. To a very limited extent prudent, persistent, analytic. is a somewhat pragmatic performer, even fast in their actions, to some extent also wide-ranging, even creative, idealistic and committed when needed.',
  'scout.result.babb':
    ' is somewhat emotional, even empathic, atmosphere sentient, interactive. largely thoughtful, analytical, negotiable, even logical, at the risk of indecision, gets stuck in things, reserved. somewhat pragmatic, even results-oriented, even wide-ranging can be found to some extent, as well as to some extent commitment to tasks as well as creativity.',
  'scout.result.bbca':
    ' is somewhat emotional, understanding, interactive, empathic even. to some extent thoughtful, persistent logician as well as negotiable, to a very limited extent practical and results-oriented,also not very fast in their actions. largely wide-ranging, imaginative, solver of wide problems, however, the risk is mental distance and being a little "feet off the ground".',
  'scout.result.bcaa':
    ' is somewhat emotional, coping well with people, even empathic, even spontaneous. very little prudent, more subjective than objective, shortsighted. to a large extent a pragmatic performer, even fast, delivering implementer, the risk is even rushing and superficiality as well as self-centeredness. to a large extent also extensive, original, instinctive, at the risk of being "feet off the ground", dogmatism as well as having things in a grim state .',
  'scout.result.cabb':
    ' is to a very limited extent emotional, not so much of a people person, nor very empathic. largely case centered, introvert, reflective, negotiable, the risk of insensitivity, excessive objectivity and indecisiveness. somewhat pragmatic, even confident, results-oriented by situation. Somewhat wide-ranging, creativity can be found to some extent, future-oriented as well.',
  'scout.result.cbab':
    ' is to a very limited extent emotional people expert, not the best possible listener of concerns of other, not very empathic. Somewhat thoughtful, even contemplative, with computational reason. To a large extent a pragmatic performer, a doer, not very persistent in ones actions, even social, at risk of rushing and decisiveness without much care of people. To some extent wide-ranging, even creative, what falls in love with is also often committed to it.',
  'scout.result.aaba':
    ' is to a large extent skilled in interaction, an understanding person, empathic, atmosphere interpreter, risk of mixing the emotional and rational worlds, blaming oneself, postponing things, to a large extent also committed, profound, analytical, viewing different options, risk is caution, indecisiveness, being reserved. to some extent a practical expert, even confident, social when needed. Largely extensive, creative, visionary, intuitive, risk of  dogmatism and dreaminess',
  'arvoviisari.question1': 'Community',
  'arvoviisari.question2': 'Flexibility to change',
  'arvoviisari.question3': 'Tolerance',
  'arvoviisari.question4': 'Justice',
  'arvoviisari.question5': 'Innovativeness',
  'arvoviisari.question6': 'Own initiative',
  'arvoviisari.question7': 'Responsibility',
  'arvoviisari.question8': 'Development willingness',
  'arvoviisari.question9': 'Rewarding',
  'arvoviisari.question10': 'Communcation',
  'arvoviisari.mostImportant': 'Most important',
  'arvoviisari.lessImportant': 'Least important',
  'arvoviisari.explanation': 'Order values based on importance.',
  'dropbox.dragOrganizationLogo': 'Drag the organization logo here',
  'dropbox.dragProfilePicture': 'Drag your profile picture here',
  'dropbox.dragAttachment': 'Drag an attachment here',
  'dropbox.dragCV': 'Drag a CV-file here',
  'dropbox.dropOrganizationLogo': 'Drop the logo here',
  'dropbox.dropProfilePicture': 'Drop the picture here',
  'dropbox.dropAttachment': 'Drop the attachment here',
  'dropbox.dropCV': 'Drop the CV here',
  'dropbox.cropInfo': 'Crop the picture as you wish',
  'profile.selectedCV': 'Selected CV',
  'profile.setDefault': 'Make default',
  'profile.isDefault': 'Default CV',
  'profile.copyCV': 'Make new copy',
  'profile.cvDescription': 'Copy description',
  'profile.copyDialogTitle': 'CV Copy',
  'profile.cvInfo': 'Copy details',
  'profile.addTitle': 'Make new copy',
  'profile.editTitle': 'Edit CV Copy',
  'profile.name': 'Name',
  'profile.description': 'Description',
  'profile.selectTeamMember.placeholder': 'Switch to team member',
  'profile.historyButton': 'CV update history',
  'profile.backButton': 'Back to previous page',
  'register.title': 'Registering',
  'register.email': 'Email',
  maxDigitsAfterDecimal: 'number field must have 2 digits after decimal or less',
  'profile.modal.minPrice': 'Min price €/h',
  'profile.modal.maxPrice': 'Max price €/h',
  'profile.modal.remoteWorkAvailable': 'Available for remote work',
  'profile.modal.remoteWorkNotAvailable': 'Not available for remote work',
  'profile.modal.priceInfo':
    'Only users with broad "Sales"-access can see this billing rate. Users with default settings do not see their billing rate.',
  'profile.modal.priceNetwork': 'Billing rate is available for network managers of {{networks}}',
  'resources.searchResults.lastFetched': 'Last fetched {{time}} ago',
  'userManual.title': 'User manual',
  reminderSent: 'Reminder sent!',
  degrees: 'degrees',
  courses: 'courses',
  certificates: 'certificates',
  'reports.skillsStatistics.header': 'Skills statistics',
  'reports.skillTotals': 'Skill totals',
  'reports.skillsStatistics.total': 'Total',
  'reports.skillsStatistics.interest': 'Interest',
  'reports.skillsStatistics.level': 'Level',
  'reports.skillsStatistics.experience': 'Experience',
  'reports.skillsStatistics.totals.experience': 'Total experience',
  'reports.skillsStatistics.totals.interest': 'Average interest',
  'reports.skillsStatistics.totals.level': 'Average level',
  'reports.skillsStatistics.totals.projectExperience': 'Total project experience',
  'reports.skillsStatistics.totals.userCount': 'User count',
  'reports.skillsStatistics.charts.upToHalfYear': 'Less than 6 months',
  'reports.skillsStatistics.charts.upToOneYear': 'Less than 1 year',
  'reports.skillsStatistics.charts.upToThreeYears': '1-2 years',
  'reports.skillsStatistics.charts.upToFiveYears': '3-4 years',
  'reports.skillsStatistics.charts.moreThanFiveYears': '5+ years',
  'reports.skillsStatistics.charts.count': 'count',
  'reports.skillsStatistics.charts.skillsLimit': 'Skills limit',
  'reports.skillsStatistics.charts.EducationInterest': 'Education interest',
  'reports.skillsStatistics.charts.EducationInterest.averageInterest': 'Average interest',
  'reports.skillsStatistics.charts.EducationInterest.averageLevel': 'Average level',
  'reports.skillsStatistics.charts.EducationInterest.difference': 'Difference',
  'reports.skillsStatistics.charts.EducationInterest.countInCvs': 'Occurrences',
  'reports.skillsStatistics.charts.EducationInterest.description':
    'Average skill interest compared to average skill level. This graf can be use to evaluate need for training, for example if average skill level is low but average skill interest is high, it means that need for training is in high demand',
  'reports.skillStatistics.charts.interest.threeOrNull': 'Medium Interest (or unspecified)',
  'reports.skillStatistics.charts.level.threeOrNull': 'Proven practical experience. (or unspecified)',
  'reports.skillsStatistics.charts.demand.averageLevel': 'Average level',
  'reports.skillsStatistics.charts.demand.averageRequiredLevel': 'Average required level',
  'reports.skillsStatistics.charts.demand.requiredCount': 'Required count',
  'reports.skillsStatistics.charts.demand.foundCount': 'Found count',
  'reports.skillsStatistics.charts.demand': 'Demand',
  'reports.skillsStatistics.charts.demand.description':
    'Skills required in assignments in past year compared to skills of users in selected team/organization (scope).',
  'reports.certificateStatistics.users': 'Users',
  'reports.certificateStatistics.totals': 'Totals',
  'reports.certificateStatistics.nameColumn': 'User',
  'reports.certificateStatistics.certificateColumn': 'Certificate',
  'reports.certificateStatistics.certifierColumn': 'Certifier',
  'reports.certificateStatistics.issueDate': 'Issue date',
  'reports.certificateStatistics.expirationDate': 'Expiration date',
  'reports.certificateStatistics.licenseNumber': 'License number',
  'reports.certificateStatistics.active': 'Active',
  'reports.certificateStatistics.expired': 'Expired',
  'reports.certificateStatistics.userCount': 'User count',
  'reports.certificateStatistics.count': 'Certificate count',
  'reports.certificateStatistics.organization': 'Organization',
  'reports.statistics.exportXLSX': 'Export in Excel',
  'reports.certificateStatistics.exportJSON': 'Export in JSON',
  'reports.certificateStatistics.certificates': 'Certificates',
  'reports.certificateTotals': 'Certificate totals',
  'reports.certificateUsers': 'Certificate Users',
  'reports.certificateCount': 'Count',
  'reports.certificateCount.title': 'Certificate count',
  'reports.certificateCount.name': 'Name',
  'reports.certificateCount.organization': 'Organization',
  'reports.certificateCount.certificateCount': 'Count',
  'reports.skillsStatistics.skillType.placeholder': 'Select type',
  'reports.skillsStatistics.skillScope.placeholder': 'Select scope',
  'reports.skillsStatistics.showUnverified': 'Show unverified',
  'reports.skillsStatistics.type.skills': 'Skills',
  'reports.skillsStatistics.type.industries': 'Industries',
  'reports.skillsStatistics.type.roles': 'Roles',
  'reports.skillsStatistics.skillsList': 'Skill list',
  'reports.skillsStatistics.filters': 'Filters',
  'navigation.hitRateReport': 'Hit rate',
  'navigation.suppliersReport': 'Suppliers',
  'reports.suppliers.name': 'Supplier name',
  'reports.suppliers.roles': 'Roles',
  'reports.suppliers.role': 'Role',
  'reports.suppliers.personCount': 'People allocated',
  'reports.suppliers.openDetails': 'Open details',
  'reports.suppliers.title': 'Suppliers',
  'reports.suppliers.details.title': 'Detailed report',
  'reports.suppliers.workorder.allocated': 'Allocated',
  'reports.suppliers.workorder.leadtime': 'Lead time (business days)',
  'reports.suppliers.workorder.name': 'Work order name',
  'reports.suppliers.workorder.hourlyRate': 'Hourly rate',
  'reports.suppliers.workorder.duration': 'Duration (business days)',
  'reports.suppliers.workorder.allocationPercentage': 'Allocation percentage',
  'reports.suppliers.workorder.contactPeople': 'Contact people',
  'reports.suppliers.workorder.rating': 'Rating',
  'reports.suppliers.workOrder.decidedAt': 'Decided at',
  'reports.suppliers.workorder.roleDescription': 'Role description',
  'reports.suppliers.workorder.startDate': 'Start date',
  'reports.suppliers.workorder.endDate': 'End date',
  'reports.suppliers.workorder.subcontractor': 'Subcontractor',
  'reports.suppliers.noData': 'No data for network',
  'reports.hitRate.title': 'Hit rate',
  'reports.hitRate.role': 'Role',
  'reports.hitRate.organization': 'Organization',
  'reports.hitRate.value': 'Hit rate',
  'reports.hitRate.peopleCount': 'People count',
  'reports.hitRate.noData': 'No data for network',
  'permissionDescriptions.companyAdmin': 'Full access (Company admin)',
  'permissionDescriptions.companyAdmin.description': 'Manages organization members and access rights.',
  'permissionDescriptions.companyAdmin.userManagement': 'Manage users',
  'permissionDescriptions.companyAdmin.permissionManagement': 'Manage permissions',
  'permissionDescriptions.companyAdmin.organizationManagement': 'Manage organization settings ',
  'permissionDescriptions.sales.teamManagement': 'Manage teams (PRO)',
  'permissionDescriptions.companyAdmin.reportAccess': 'Access all reports (PRO)',
  'permissionDescriptions.sales': 'Broad access (Sales)',
  'permissionDescriptions.sales.description': 'Access to sales operations tools and views.',
  'permissionDescriptions.sales.accessSearch': 'Access search',
  'permissionDescriptions.sales.userProfileManagement': 'Manage user profile data',
  'permissionDescriptions.sales.accessOtherCvExport': 'Export user CV as pdf',
  'permissionDescriptions.sales.manageAssignments': 'View and manage assignments',
  'permissionDescriptions.sales.manageAllocations': 'Manage allocations',
  'permissionDescriptions.sales.accessDashboard': 'Access resourcing overview (PRO)',
  'permissionDescriptions.sales.contactPerson': 'Are organization contact people',
  'permissionDescriptions.sales.notifications': 'Get notifications about network assignments',
  'permissionDescriptions.teamLeader': 'Team access (Team Leader)',
  'permissionDescriptions.teamLeader.description': 'Access to the team management.',
  'permissionDescriptions.teamLeader.manageTeamMembers': 'Manage team members',
  'permissionDescriptions.teamLeader.userProfileManagement': 'Manage team members profile data',
  'permissionDescriptions.teamLeader.accessOtherCvExport': 'Export team members CV as pdf',
  'permissionDescriptions.teamLeader.manageAllocations': 'Manage team members allocations',
  'permissionDescriptions.allUsers': 'Limited access (All users) ',
  'permissionDescriptions.allUsers.description': 'Own profile data management.',
  'permissionDescriptions.allUsers.manageProfile': 'Manage own CV data',
  'permissionDescriptions.allUsers.manageAllocations': 'Manage own allocations',
  'permissionDescriptions.allUsers.manageOwnCvExport': 'Manage own CV Export',
  'permissionDescriptions.allUsers.accessOwnTeam': 'See own team members (PRO)',
  'permissionDescriptions.allUsers.accessCommonReports': 'Access common reports (PRO)',
  'permissionDescriptions.allUsers.scoutSoft': 'Access Scout (Soft-skills) (PRO)',
  'permissionDescriptions.tooltip': 'Show permission descriptions',
  'navigation.notifications': 'Notifications',
  'notifications.title': 'Notifications',
  'notifications.toAssignment': 'To assignment',
  'notifications.toProfile': 'To profile',
  'notifications.status': 'Status',
  'notifications.description': 'Description',
  'notifications.read': 'Read',
  'notifications.unread': 'Unread',
  'notifications.readAll': 'Mark all as read',
  'notifications.markedAsRead': 'Marked as read',
  'admin.title.notifications': 'Notifications',
  'admin.title.addNotification': 'Send notification',
  'admin.notifications.accounts': 'Activated accounts (use CTRL to select multiple)',
  'admin.notifications.message': 'Message',
  'admin.notifications.header': 'Heading',
  'admin.notifications.allActivated': 'Select all',
  noRoleSet: 'No role set',
  'assignmentView.description': 'Description',
  'assignmentView.attachments.action': 'Download',
  'assignmentView.role': 'Role',
  'assignmentView.price': 'Price',
  'assignmentView.negotiable': 'Negotiable',
  'assignmentView.allocation': 'Allocation',
  'assignmentView.propose.header': 'Suitable candidate?',
  'assignmentView.propose.header2': 'Propose your candidates in Caleo!',
  'assignmentView.login': 'Log in',
  'assignmentView.noAccount': 'Register',
  'assignmentView.assignment': 'Assignment',
  'assignmentView.assignmentName': 'Assignment name',
  'assignmentView.contactPerson': 'Contact person',
  'assignmentView.subcontractorAllowed': 'Subcontractors allowed',
  'assignmentView.customer': 'Customer',
  'assignmentView.location': 'Location',
  'assignmentView.startDate': 'Start date',
  'assignmentView.endDate': 'End date',
  'assignmentView.deadline': 'Deadline',
  'assignmentView.remoteAllowed': 'Remote',
  'assignmentView.contactDetails': 'Contact details',
  'assignmentView.contactName': 'Name',
  'assignmentView.telephone': 'Telephone',
  'assignmentView.attachmentsTitle': 'Attachments',
  'assignmentView.emptyRoles': 'Show empty roles',
  'allocationinputcomponent.personplaceholder': 'Select person',
  'allocation.verified': 'Verified',
  'allocation.tentative': 'Tentative',
  'allocation.otherWork': 'Other work',
  'allocation.assignment': 'Assignment',
  'allocation.absence': 'Absence',
  'allocation.free': '0%',
  'allocation.partial': '1-99%',
  'allocation.full': '100%+',
  'allocation.percentage': 'Allocated',
  'allocation.overview.summary': 'Summary',
  'allocation.overview.edit': 'Edit',
  'allocation.overview.timeScope': 'Time scope',
  'allocation.noResults': 'No results with these filters.',
  'allocation.noResults.info': 'Select a scope and adjust filters to get better results.',
  'allocation.dragEditHeader': 'Edit allocations',
  'allocation.dragSubHeader': 'Quick edit',
  'allocation.dragHelp': 'You can edit length by draging from block ends.',
  'allocation.dragNoAllocations': 'No allocations within time scope!',
  'allocation.dragTableHeader': 'Allocations',
  'allocation.endedTooltip': 'Allocation ended',
  'allocation.closeToEndTooltip': 'Allocation close to end',
  oneMonth: '1 month',
  twoMonths: '2 months',
  threeMonths: '3 months',
  fourMonths: '4 months',
  'network.registerModal.addTitle': 'Register',
  'network.registerModal.freelancer': 'Freelancer',
  'network.registerModal.organization': 'Organization',
  'network.registerModal.registerToCaleo': 'Register to Caleo',
  'network.registerModal.joining': 'Joining {{network}}',
  'network.registerModal.name': 'Name',
  'network.registerModal.companyIdentifier': 'Company identifier',
  'network.registerModal.address': 'Street address',
  'network.registerModal.city': 'City',
  'network.registerModal.postalCode': 'Postal code',
  'network.registerModal.country': 'Country',
  'network.registerModal.user': 'Users',
  'network.registerModal.permissions': 'Permissions',
  'network.registerModal.firstName': 'First name',
  'network.registerModal.lastName': 'Last name',
  'network.registerModal.email': 'Email',
  'network.registerModal.submit': 'Submit',
  'network.registerModal.defaultLanguage': 'Default user interface language',
  'network.registerModal.language': 'User interface language',
  'network.registerModal.telephone': 'Phone number',
  'network.registerUser.addTitle': 'Add user',
  'network.registerUser.firstName': 'First name',
  'network.registerUser.lastName': 'Last name',
  'network.registerUser.telephone': 'Phone number',
  'network.registerUser.email': 'Email',
  'network.registerUser.notifications': 'Receives notifications from {{network}}',
  'network.registerUser.permissions': 'Additional permissions',
  'network.registerUser.sales': 'Sales',
  'network.registerUser.companyAdmin': 'Company admin',
  'network.registerUser.teamLeader': 'Team leader',
  'network.registerUser.add': 'Add',
  'network.registerUser.language': 'User interface language',
  'network.registerMember': 'Register new member',
  'cvHistory.header': 'CV update history',
  'cvHistory.date': 'Date',
  'cvHistory.editor': 'Editor',
  'cvHistory.section': 'Section',
  'cvHistory.Employer': 'work histories',
  'cvHistory.Project': 'projects',
  'cvHistory.Education': 'education',
  'cvHistory.Course': 'courses',
  'cvHistory.Certificate': 'certificates',
  'cvHistory.skill': 'skills',
  'cvHistory.role': 'roles',
  'cvHistory.industry': 'industries',
  'cvHistory.LanguageSkill': 'languages',
  'cvHistory.Reference': 'references',
  'cvHistory.Url': 'Url',
  'cvHistory.About': 'profile',
  'cvHistory.Pricing': 'pricing',
  'cvHistory.Remote': 'remote work',
  'admin.permissions.groups': 'Groups',
  'admin.permissions.users': 'Users',
  'admin.permission.user.firstName': 'First name',
  'admin.permission.user.lastName': 'Last name',
  'admin.permission.user.organization': 'Organization',
  'admin.permission.editUser': 'Edit user',
  'admin.permission.user.edit': 'Edit user permissions',
  'admin.permission.addGroup': 'Add group',
  'admin.permission.groupName': 'Group name',
  'admin.permission.groupOrganization': 'Group organization',
  'assignment-count': 'New assignment count',
  'freelancer-count': 'New freelancer count',
  'organization-count': 'New organization count',
  skillName: 'Skill name',
  skillCount: 'Skill count',
  network: 'Network',
  startDate: 'Start date',
  endDate: 'End date',
  skills: 'Skills',
  languages: 'Languages',
  export: 'Export',
  import: 'Import trends',
  'salesPool.title': 'Proposed candidates',
  'salesPool.allocations': 'Allocations',
  'salesPool.itemExists': 'Sales pool CV already exists!',
  'salesPool.expiresIn': 'Expires in {{date}} weeks',
  'salesPool.anonymous': 'Anonymous',
  'salesPool.anonymousFileInfo': 'Files are not anonymized',
  'salesPool.generateLink': 'Generate link',
  'salesPool.linkExpired': 'The link has expired!',
  'salesPool.contactCreator': 'Please contact the link author for a new link.',
  'salesPool.copyToClipboard': 'Copy to clipboard',
  'salesPool.itemAdded': 'User added to sales pool',
  'salesPool.allocations.legend.full': 'fully reserved: not available',
  'salesPool.allocations.legend.notFull': 'reserved: partially available',
  'salesPool.allocations.legend.empty': 'available',
  'salesPool.allocations.legend.fullTentative': 'planned full reservation: possibly available',
  'salesPool.allocations.legend.notFullTentative': 'planned partial reservation: possibly available',
  'salesPool.titleField': 'Title',
  'salesPool.allocationsVisible': 'Show allocations',
  'salespools.title': 'Sales pools',
  'salespool.table.delete': 'Delete sales pool',
  'salesPool.itemEditing': 'Edit opened',
  'salespool.table.edit': 'Edit sales pool',
  'salespool.table.copy': 'Copy sales pool',
  'salespool.table.view': 'View proposed',
  'salespool.table.items': 'Proposed',
  'salespool.table.itemCount': 'Proposed count',
  'salespool.table.allocationsVisible': 'Allocations visible',
  'salespool.table.anonymous': 'Anonymous',
  'salespool.table.expiration': 'Expiration',
  'salespool.table.created': 'Created',
  'salespool.table.title': 'Name',
  'salespool.table.owner': 'Owner',
  'salespool.view.title': 'Sales pool view',
  'salesPool.updateSalesPool': 'Update sales pool',
  'salesPool.reset': 'Reset',
  'salesPool.itemCopied': 'Sales pool copied!',
  'salespool.delete.header': 'Are you sure you want to delete sales pool?',
  'salespool.delete.confirmation': 'Name: {{name}}',
  file: 'File',
  selection: 'Selection',
  'admin.title.parseCV': 'Parse CV',
  'admin.parse.setup.addTitle': 'Setup parse',
  'admin.parse.setup.files': 'Added files:',
  'admin.parse.setup.organizationId': 'Organization',
  'admin.parse.setup.siteId': 'Site',
  'admin.parse.setup.send': 'Send',
  'admin.parse.setup.creditsLeft': 'Parsing credits left: {{credits}}.',
  'admin.parse.setup.contactSupport': 'Please contact support.',
  'admin.parse.setup.maxFiles': 'Maximum files per request',
  'admin.parse.name': 'Name',
  'admin.parse.email': 'Email',
  'admin.parse.results.header': 'Results',
  'admin.parse.results.save': 'Save',
  'admin.parse.results.activation': 'Send activation emails',
  'admin.parse.results.networkVisible': 'Network visibility',
  'admin.parse.result.cvData': 'CV Data',
  'admin.parse.result.firstName': 'First name',
  'admin.parse.result.lastName': 'Last name',
  'admin.parse.result.email': 'Email',
  'admin.parse.result.info': 'You can edit data in the generated profile after the import.',
  'admin.parse.result.role': 'Role',
  'admin.parse.result.about': 'Description',
  'admin.parse.result.skills': 'Skills',
  'admin.parse.result.skill': 'Name',
  'admin.parse.result.lastUsed': 'Last used',
  'admin.parse.result.experienceMonths': 'Experience',
  'admin.parse.result.workHistory': 'Work experience',
  'admin.parse.result.organization': 'Organization',
  'admin.parse.result.startDate': 'Start date',
  'admin.parse.result.endDate': 'End date',
  'admin.parse.result.description': 'Description',
  'admin.parse.result.education': 'Education',
  'admin.parse.result.school': 'School',
  'admin.parse.result.degree': 'Degree',
  'admin.parse.result.languages': 'Language skills',
  'admin.parse.result.language': 'Language',
  'admin.parse.result.level': 'Level',
  'admin.parse.accounts.header': 'Created accounts',
  'admin.parse.accounts.errors': 'Errors',
  'admin.parse.accounts.back': 'Back to start',
  'admin.parse.emailWarning': 'Email is missing from data ',
  'admin.parse.firstNameWarning': 'First name is missing from data ',
  'admin.parse.lastNameWarning': 'Last name is missing from data',
  'email-error': 'email was already used for account',
  'file-already-added': 'File already added!',
  'admin.title.myNetworks': 'Networks',
  'admin.myNetworks.organization': 'Organization',
  'admin.myNetworks.visiblePeople': 'Visible users',
  'admin.myNetworks.hiddenPeople': 'Non-visible users',
  'admin.myNetworks.networks': 'Network memberships',
  'admin.myNetworks.table.name': 'Name',
  'admin.myNetworks.table.description': 'Description',
  'admin.myNetworks.anonymouslySearchable':
    'All Caleo users can find your visible user with anonymized information. See the anonymous results from search page.',
  'admin.myNetworks.notAnonymouslySearchable':
    'Network manager organization can see and find your visible user details. Allocation details are hidden.',
  'admin.myNetworks.organizationData': 'Organization data',
  'profile.language.add': 'Add language',
  'profile.cvDelete': 'Delete this CV',
  'proposals.search': 'Search proposals',
  'proposals.search.showUnverified': 'Show unverified skills',
  'proposals.search.networks': 'Search from',
  'proposals.search.roleName': 'Role name',
  'proposals.search.startDate': 'Start date',
  'proposals.search.endDate': 'End date',
  'proposals.search.clear': 'Clear',
  'proposals.search.search': 'Search',
  'proposals.search.proposer': 'Proposer',
  'proposals.search.createAt': 'Created at',
  'proposals.search.requirements': 'Role requirements',
  'proposals.search.description': 'Role description',
  'proposals.search.openAssignment': 'To assignment',
  'proposals.search.proposalDetails': 'Proposal details',
  'proposals.search.additionalInfo': 'Additional info',
  'assignments.search': 'Search proposals',
  'navigation.releases': 'Release notes',
  'releaseNotes.title': 'Release notes',
  'releaseNotes.version': 'Version',
  'releaseNotes.noNotes': 'No release notes',
  'admin.releaseNotes': 'Release notes',
  'admin.releaseNotes.addTitle': 'Add release note',
  'admin.releaseNotes.editTitle': 'Edit release note',
  'admin.releaseNotes.title': 'Title',
  'admin.releaseNotes.version': 'Version',
  'admin.releaseNotes.createdAt': 'Created at',
  'admin.releaseNotes.note': 'Note',
  'admin.releaseNotes.delete.confirmationText': 'Delete {{name}} release note?',
  'search.quickSearch': 'Quick search',
  'search.detailedSearch': 'Detailed search',
  'search.from': 'From',
  'search.keywords': 'You can use multiple search terms.',
  'search.submit': 'Search',
  'search.skill.name': 'Skill',
  'search.role.name': 'Role',
  'search.industry.name': 'Industry',
  'search.name': 'Name',
  'search.reset': 'Clear selection',
  'search.results': 'Search results',
  'search.lastFetched': 'Last fetched {{time}} ago',
  'search.notFound': "Didn't find what you were looking for?",
  'search.notFoundDescription': 'Request a free shortlist of candidates!',
  'search.sendRequest': 'Send request',
  'search.fullProfile': 'Full profile',
  'search.exactMatch': 'Exact match',
  'search.criteria': 'Skill criteria',
  'search.experience': 'Experience',
  'search.level': 'Level',
  'search.requiredProjects': 'Used in projects',
  'search.years': 'Years',
  'search.unspecified': 'Unspecified',
  'search.projects': 'Projects',
  'search.save': 'Save',
  'search.remove': 'Remove',
  'search.proposeAssignment': 'Propose assignment',
  'search.location': 'City',
  'search.availability': 'Availability',
  'search.certificate': 'Certificate',
  'search.degree': 'Degree',
  'search.course': 'Course',
  'search.language': 'Language',
  'search.resultsCount': 'Results: {{from}}-{{to}} of {{of}}',
  'navigation.workorders': 'Work Orders',
  'workorders.overview': 'Overview',
  'workorder.modal.addTitle': 'New work order',
  'workorder.modal.roles': 'Roles',
  'workorder.modal.generalTitle': 'General',
  'workorder.modal.customerTitle': 'Customer',
  'workorder.modal.rolesTitle': 'Roles',
  'workorder.modal.attachmentsTitle': 'Attachments',
  'workorder.modal.editTitle': 'Edit work order',
  'workorder.modal.shareTitle': 'Copy and share work order',
  'workorder.modal.copyTitle': 'Copy work order',
  'workorder.modal.share': 'Share to supplier networks',
  'workorder.modal.copy.save': 'Create new work order',
  'workorder.modal.share.save': 'Share and save',
  'workorder.modal.customerId': 'Customer',
  'workorder.modal.copy': 'Copy',
  'workorder.share.broker': 'Work order is shared and published to networks.',
  'workorder.share.info': 'Work order share is waiting for maintainers approval.',
  'workorder.internal': 'Show internal',
  'workorder.external': 'Show external',
  'workorder.shared': 'Show shared',
  'workorder.outdated': 'Show outdated',
  'workorder.favorite': 'Show favorites',
  'workorder.contactPeople': 'Filter by contact people',
  'workorder.filterBySkill': 'Filter by skills',
  'workorder.notifySelector': 'Select users to notify',
  'workorder.internal.tooltip': 'Not shared work orders from own organization.',
  'workorder.external.tooltip': 'Work orders that are shared to your organization.',
  'workorder.shared.tooltip': 'Shared work orders from own organization.',
  'workorder.outdated.tooltip': 'Work orders that are overdue by deadline.',
  'workorder.favorite.tooltip': 'Work orders that are set as favorites.',
  'workorder.description': 'Work order description',
  'workorder.autoRejectEmail': '(Automatically closes proposals and sends closing notifications)',
  'workorder.modal.view': 'View work order',
  'workorder.modal.viewPropose': 'View and propose to work order',
  'workorder.modal.next': 'Next',
  'workorder.modal.save': 'Save',
  'workorder.modal.name': 'Work order name',
  'workorder.modal.startDate': 'Start Date',
  'workorder.modal.endDate': 'End Date',
  'workorder.modal.workloadEstimate': 'Workload estimate (days)',
  'workorder.modal.country': 'Country',
  'workorder.modal.city': 'City',
  'workorder.modal.subcontractorAllowed': 'Subcontractors allowed',
  'workorder.modal.remoteAllowed': 'Remote allowed',
  'workorder.modal.deadline': 'Deadline',
  'workorder.modal.description': 'Description',
  'workorder.modal.customer': 'Customer',
  'workorder.modal.customerContactId': 'Customer contact',
  'workorder.modal.additionalInfo': 'Additional info',
  'workorder.modal.location': 'Location',
  'workorder.modal.attachments.description': 'Description',
  'workorder.modal.attachments.upload-action': 'Upload',
  'workorder.modal.attachments.download-action': 'Download',
  'workorder.modal.roles.hourlyPrice': 'Price',
  'workorder.modal.roles.allocation': 'Allocation',
  'workorder.modal.roles.createCopy': 'Create a new role from copy',
  'workorder.modal.personInCharge': 'Person in charge',
  'workorder.modal.contactPeople': 'Contact people',
  'workorder.modal.contactName': 'Name',
  'workorder.modal.publicView': 'Viewable without login',
  'workorder.modal.contactPersonId': 'Contact person',
  'workorder.modal.notifyTo': 'Send email notification to',
  'workorder.modal.publicUrl': 'Public URL',
  'workorder.modal.copyToClipboard': 'Copy to clipboard',
  'workorder.modal.lastChangedBy': 'Status last changed by: {{name}}',
  'workorder.modal.statusBySystem': 'System',
  'workorder.modal.template': 'Template',
  'workorder.modal.templateName': 'Template name',
  'workorder.modal.templateCreate': 'Create',
  'workorder.modal.templateUpdate': 'Update',
  'workorder.modal.fileProposalAllowed': 'File proposal allowed',
  'workorder.modal.basicInfo': 'Basic information',
  'workorder.modal.details': 'Details',
  'workorder.modal.sharingOptions': 'Sharing options',
  'workorder.modal.moreDetails': 'Description',
  'workorder.modal.attachments': 'Attachments',
  'workorder.modal.useTemplate': 'Use template',
  'workorder.modal.helperTextContactPerson': 'Network sharing so contact people set in sharing options.',
  'workorder.modal.status': 'Status',
  'workorder.modal.info': 'Info',
  'workorder.modal.contactInfo': 'Contact info',
  'workorder.modal.people': 'People',
  'workorder.table.name': 'Name',
  'workorder.table.customer': 'Customer',
  'workorder.table.status': 'Status',
  'workorder.table.created': 'Accepting proposals',
  'workorder.table.inProgress': 'Offer in progress',
  'workorder.table.resolved': 'Resolved',
  'workorder.table.roles': 'Roles',
  'workorder.table.inCharge': 'In Charge',
  'workorder.table.startDate': 'Start Date',
  'workorder.table.endDate': 'End Date',
  'workorder.table.attachments': 'Attachments',
  'workorder.table.deadline': 'Deadline',
  'workorder.table.roleTooltip.header': 'Show search results with',
  'workorder.table.roleTooltip.location': 'Location',
  'workorder.table.roleTooltip.skills': 'Skills',
  'workorder.table.createdAt': 'Created at',
  'workorder.roles.table.name': 'Role Name',
  'workorder.roles.add': 'Add role',
  'workorder.roles.applyChanges': 'Apply changes',
  'workorder.roles.table.allocationPercentage': 'Allocation %',
  'workorder.roles.table.negotiable': 'Negotiable',
  'workorder.roles.table.hourlyPrice': 'Price €/h',
  'workorder.roles.table.skills': 'Skills',
  'workorder.roles.table.languages': 'Languages',
  'workorder.roles.table.description': 'Description',
  'workorder.roles.table.assignedPeople': 'Assigned',
  'workorder.roles.table.assignedPeople.name': 'Name',
  'workorder.roles.table.assignedPeople.allocation': 'Allocation',
  'workorder.roles.table.assignedPeople.state': 'State',
  'workorder.roles.table.assignedPeople.startDate': 'Start Date',
  'workorder.roles.table.assignedPeople.endDate': 'End Date',
  'workorder.roles.allocations': 'Role Allocations',
  'workorder.roles.allocation.helperText':
    'This role can not be deleted or edited because it is used in one or more allocations. For editing or removing the role, all related allocations must be deleted.',
  'workorder.skill.name': 'Role Name',
  'workorder.skill.allocationPercentage': 'Allocation %',
  'workorder.skill.hourlyPrice': 'Price €/h',
  'workorder.skill.description': 'Description',
  'workorder.skill.editTitle': 'Editing role',
  'workorder.skill.addTitle': 'Add new role',
  'workorder.skill.role.view': 'View role',
  'workorder.role.requirement.skill.skillId': 'Skill',
  'workorder.role.requirement.skill.level': 'Level',
  'workorder.role.requirement.skill.experienceMonths': 'Experience',
  'workorder.role.requirement.industry.skillId': 'Industry',
  'workorder.role.requirement.industry.level': 'Level',
  'workorder.role.requirement.industry.experienceMonths': 'Experience',
  'workorder.role.requirement.role.skillId': 'Role',
  'workorder.role.requirement.role.level': 'Level',
  'workorder.role.requirement.role.experienceMonths': 'Experience',
  'workorder.role.requirement.language.languageCode': 'Language',
  'workorder.role.requirement.language.level': 'Level',
  'workorder.role.allocation.verified': 'Allocation verified',
  'workorder.role.allocation.multipleVerified': 'Multiple allocations are verified or tentative',
  'workorder.role.allocation.tentative': 'One or more tentative allocations',
  'workorder.role.allocation.deleteConfirm': 'Are you sure you want to delete allocation?',
  'workorder.role.description': 'Role description',
  'workorder.propose.openProfile': 'Open profile',
  'workorder.propose.noCandidates': 'Uh oh. No matching candidates.',
  'workorder.propose.noCandidates.description': 'Candidates must be available and the requested skills must match.',
  'workorder.propose.noCandidates.backButton': 'Back to work orders',
  'workorder.propose': 'Propose candidates',
  'workorder.propose.proposed': 'Proposed',
  'workorder.propose.confirmed': 'Verified',
  'workorder.propose.addTitle': 'Create proposal',
  'workorder.propose.role': 'Role',
  'workorder.propose.criteria': 'Search criteria',
  'workorder.propose.location': 'Location',
  'workorder.propose.remoteAllowed': 'Remote allowed',
  'workorder.propose.start': 'Start date',
  'workorder.propose.end': 'End date',
  'workorder.propose.allocationPercent': 'Allocation percent',
  'workorder.propose.additionalInformation': 'Additional information',
  'workorder.propose.minPrice': 'Minimum price €/h',
  'workorder.propose.maxPrice': 'Target price €/h',
  'workorder.propose.added': 'Added to proposal',
  'workorder.propose.add': 'Add to proposal',
  'workorder.propose.remove': 'Remove from proposal',
  'workorder.propose.save': 'Send proposal',
  'workorder.propose.update': 'Update proposal',
  'workorder.propose.roleCandidates': 'Role Candidates',
  'workorder.propose.role.note.createdAt': 'Last updated {{date}} by ',
  'workorder.propose.role.note.showVersion': 'Show version',
  'workorder.propose.role.note.versionHistory': 'Version history',
  'workorder.propose.role.note.version': 'Version {{number}}',
  'workorder.propose.candidates': 'Show candidates',
  'workorder.propose.role.header': 'Proposals',
  'workorder.propose.role.requirements': 'Requirements',
  'workorder.propose.role.location': 'Location',
  'workorder.propose.role.remote': 'Remote allowed',
  'workorder.propose.role.startDate': 'Start date',
  'workorder.propose.role.endDate': 'End date',
  'workorder.propose.role.allocation': 'Allocation',
  'workorder.propose.role.downloadCV': 'Download CV',
  'workorder.propose.role.pricing': 'Pricing',
  'workorder.propose.role.match': 'Match',
  'workorder.propose.role.price': 'Hourly price',
  'workorder.propose.role.no-contact': 'The organization has no contact person and the proposer has been removed!',
  'workorder.propose.role.questions': 'Questions ({{yes}}/{{count}})',
  'workorder.propose.role.status': 'Status',
  'workorder.propose.role.createdAt': 'Created at',
  'workorder.propose.role.additionalInfo': 'Additional information',
  'workorder.propose.role.noEndDate': 'No end',
  'workorder.propose.role.contactPerson': 'Contact person',
  'workorder.propose.role.organization': 'Organization',
  'workorder.propose.role.email': 'Email',
  'workorder.propose.role.telephone': 'Telephone',
  'workorder.propose.price': 'Hourly price',
  'workorder.propose.bestOffer': 'best offer',
  'workorder.propose.info':
    'Tentative allocation will be added to the proposed users. Work order owner will be in touch.',
  'workorder.propose.downloadAttachment': 'Download attachment',
  'workorder.propose.freelancerCopy': 'Send me a email copy of the proposal',
  'workorder.propose.sendCopiesTo': 'Send copy of the proposal to',
  'workorder.proposed.proposeToOwn': 'Propose on behalf of supplier',
  'workorder.propose.available': 'Show available candidates',
  'workorder.propose.showAll': 'Show all',
  'workorder.propose.filterName': 'Filter by name',
  'workorder.propose.status.proposed': 'Proposed',
  'workorder.propose.status.accepted': 'Accepted',
  'workorder.propose.status.rejected': 'Rejected',
  'workorder.propose.status.closed': 'Closed',
  'workorder.propose.reject': 'Reject',
  'workorder.propose.rejectConfirm': 'Reject proposal',
  'workorder.propose.rejectMessage': 'Reject message',
  'workorder.propose.workorderDescription': 'Work order description',
  'workorder.propose.roleDescription': 'Role description',
  'workorder.propose.assingmentResolved': 'Work order resolved, proposing closed!',
  'workorder.propose.yesLabel': 'Reject',
  'workorder.propose.noLabel': 'Cancel',
  'workorder.propose.details': 'Work Order Details',
  'workorder.propose.roles': 'Roles',
  'workorder.propose.role.note': 'Notepad',
  'workorder.attachments.name': 'Name',
  'workorder.attachments.size': 'Size',
  'workorder.attachments.description': 'Description',
  'workorder.view.tabs.list': 'List',
  'workorder.view.tabs.people': 'People',
  'workorders.proposals': 'Proposals',
  'workorders.proposals.outdated': 'Show outdated',
  'workorders.proposals.viewProposal': 'View proposal',
  'workorders.proposals.openWorkOrder': 'Open work order',
  'workorders.proposals.viewHeader': 'Proposal details',
  'workorders.proposals.pricing': 'Pricing',
  'workorders.proposals.additionalInfo': 'Additional information',
  'workorders.proposals.noInfo': 'No additional information',
  'workorders.proposals.edit': 'Edit proposal',
  'workorders.proposals.joinChat': 'Join chat',
  'proposals.table.workorder': 'Work order',
  'workorders.title': 'Work orders',
  'workorder.modal.AssignmentVisibilities': 'Share to supplier networks',
  'workorderView.description': 'Description',
  'workorderView.attachments.action': 'Download',
  'workorderView.role': 'Role',
  'workorderView.price': 'Price',
  'workorderView.negotiable': 'Negotiable',
  'workorderView.allocation': 'Allocation',
  'workorderView.propose.header': 'Suitable candidate?',
  'workorderView.propose.header2': 'Propose your candidates in Caleo!',
  'workorderView.login': 'Log in',
  'workorderView.noAccount': 'Register',
  'workorderView.workorder': 'Work order',
  'workorderView.workorderName': 'Work order name',
  'workorderView.contactPerson': 'Contact person',
  'workorderView.subcontractorAllowed': 'Subcontractors allowed',
  'workorderView.customer': 'Customer',
  'workorderView.location': 'Location',
  'workorderView.startDate': 'Start date',
  'workorderView.endDate': 'End date',
  'workorderView.deadline': 'Deadline',
  'workorderView.remoteAllowed': 'Remote',
  'workorderView.contactDetails': 'Contact details',
  'workorderView.contactName': 'Name',
  'workorderView.telephone': 'Telephone',
  'workorderView.attachmentsTitle': 'Attachments',
  'workorderView.emptyRoles': 'Show empty roles',
  'proposals.search.openWorkOrder': 'To work order',
  'workorders.search': 'Search proposals',
  'workorder.propose.assignmentDescription': 'Work order description',
}

const en = Object.freeze(enValues)

export default en
