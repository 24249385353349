import i18n from 'i18next'
import * as Yup from 'yup'
import { INetworkTranslation } from 'types/networkInterfaces'

/**
 * Project validation schema.
 *
 * @returns Yup validation object.
 */
export const projectSchema = () => {
  return Yup.object({
    startDate: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.string().nullable(),
    customerName: Yup.string().required(i18n.t('fieldRequired')),
    employerName: Yup.string().required(i18n.t('fieldRequired')),
    primaryRole: Yup.string(),
    name: Yup.string().required(i18n.t('fieldRequired')),
    description: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.project.modal.description') })
    ),
    ProjectRoles: Yup.array().of(
      Yup.object().shape({
        skillId: Yup.number(),
      })
    ),
  })
}

/**
 * Profile validation schema.
 *
 * @returns Yup validation object.
 */
export const profileSchema = () => {
  return Yup.object({
    firstName: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.modal.firstName') })),
    lastName: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.modal.lastName') })),
    telephone: Yup.string().nullable(),
    streetAddress: Yup.string().nullable(),
    city: Yup.string().nullable(),
    country: Yup.string().nullable(),
  })
}

/**
 * Profile phone required validation schema.
 *
 * @returns Yup validation object.
 */
export const profileRequiredSchema = () => {
  return Yup.object({
    firstName: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.modal.firstName') })),
    lastName: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.modal.lastName') })),
    telephone: Yup.string()
      .matches(
        /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{2,4}[ -]?[0-9]{2,4}$/,
        i18n.t('profile.modal.telephoneError')
      )
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.modal.telephone') })),
    streetAddress: Yup.string().nullable(),
    city: Yup.string().nullable(),
    country: Yup.string().nullable(),
  })
}

/**
 * Skill validation schema.
 *
 * @returns Yup validation object.
 */
export const skillSchema = () => {
  return Yup.object({
    skillId: Yup.number().integer().required(i18n.t('fieldRequired')),
    level: Yup.number().integer().positive(i18n.t('error.positive')).required(i18n.t('fieldRequired')),
    interestLevel: Yup.number().integer().moreThan(-1, i18n.t('error.positive')).nullable(),
    experienceMonths: Yup.number()
      .typeError(i18n.t('numberTypeError'))
      .required(i18n.t('fieldRequired'))
      .positive(i18n.t('error.positive')),
    lastUsed: Yup.number()
      .integer()
      .positive(i18n.t('error.positive'))
      .typeError(i18n.t('numberTypeError'))
      .required(i18n.t('fieldRequired')),
  })
}

/**
 * Short skill validation schema.
 */
export const shortSkillSchema = Yup.object({
  skillId: Yup.number().required(i18n.t('fieldRequired')),
  level: Yup.number().moreThan(-1, i18n.t('error.positive')).nullable(),
  experienceMonths: Yup.number()
    .typeError(i18n.t('numberTypeError'))
    .required(i18n.t('fieldRequired'))
    .positive(i18n.t('error.positive')),
})

/**
 * Employer validation schema.
 *
 * @returns Yup validation object.
 */
export const employerSchema = () => {
  return Yup.object({
    startDate: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.string().nullable(),
    name: Yup.string().required(i18n.t('fieldRequired')),
    description: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.employer.modal.description') })
    ),
    primaryRole: Yup.string(),
    EmployerRoles: Yup.array().of(
      Yup.object().shape({
        skillId: Yup.number(),
      })
    ),
  })
}

/**
 * Education validation schema.
 *
 * @returns Yup validation object.
 */
export const educationSchema = () => {
  return Yup.object({
    startDate: Yup.date().required(i18n.t('fieldRequired')),
    dateResolution: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.date().nullable(),
    degree: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.education.modal.degree') })),
    school: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.education.modal.school') })),
    description: Yup.string().nullable(),
  })
}

/**
 * Certificate validation schema.
 *
 * @returns Yup validation object.
 */
export const certificateSchema = () => {
  return Yup.object({
    licenceNumber: Yup.string().nullable(),
    url: Yup.string().nullable(),
    issueDate: Yup.date().required(i18n.t('fieldRequired')),
    dateResolution: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.date().nullable(),
    certificate: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.certificate.modal.certificate') })
    ),
    certifier: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.certificate.modal.certifier') })
    ),
    description: Yup.string().nullable(),
  })
}

/**
 * Course validation schema.
 *
 * @returns Yup validation object.
 */
export const courseSchema = () => {
  return Yup.object({
    startDate: Yup.date().required(i18n.t('fieldRequired')),
    dateResolution: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.date().nullable(),
    course: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.course.modal.course') })),
    organizer: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.course.modal.organizer') })),
    description: Yup.string().nullable(),
  })
}

/**
 * Language validation schema.
 *
 * @returns Yup validation object.
 */
export const languageSchema = () => {
  return Yup.object({
    languageCode: Yup.string().required(i18n.t('fieldRequired')),
    level: Yup.number().moreThan(0, i18n.t('language.levelMinError')).required(i18n.t('fieldRequired')),
  })
}

/**
 * Reference validation schema.
 *
 * @returns Yup validation object.
 */
export const referenceSchema = () => {
  return Yup.object({
    firstName: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.reference.modal.firstName') })
    ),
    lastName: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('profile.reference.modal.lastName') })
    ),
    company: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.reference.modal.company') })),
    letter: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('profile.reference.modal.letter') })),
  })
}

/**
 * Own allocation schema.
 *
 * @returns Yup validation object.
 */
export const ownAllocationSchema = () => {
  return Yup.object({
    startDate: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.string().nullable().required(i18n.t('fieldRequired')),
    type: Yup.string().required(i18n.t('fieldRequired')),
    assignmentId: Yup.number().when('type', {
      is: type => type && type === 'project',
      then: Yup.number().integer().required(i18n.t('fieldRequired')),
      otherwise: Yup.number().integer().nullable(),
    }),
    roleId: Yup.number().when('assignmentId', {
      is: assignmentId => assignmentId,
      then: Yup.number().integer().required(i18n.t('fieldRequired')),
      otherwise: Yup.number().integer().nullable(),
    }),
    state: Yup.number().integer().required(i18n.t('fieldRequired')),
    percent: Yup.number()
      .typeError(i18n.t('numberTypeError'))
      .required(i18n.t('fieldRequired'))
      .integer()
      .min(1, i18n.t('allocationPercentage.minValue'))
      .max(100, i18n.t('allocationPercentage.maxValue')),
    information: Yup.string().nullable(),
  })
}

/**
 * Admin employee freelancer validation schema.
 *
 * @returns Yup validation object.
 */
export const adminEmployeeFreelancerSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('emailIsNotValid'))
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('email') })),
    Person: Yup.object({
      firstName: Yup.string().required(i18n.t('fieldRequired')),
      lastName: Yup.string().required(i18n.t('fieldRequired')),
      visibility: Yup.string().required(i18n.t('fieldRequired')),
    }),
  })
}

/**
 * Admin employee schema.
 *
 * @returns Yup validation object.
 */
export const adminEmployeeSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email(i18n.t('emailIsNotValid'))
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('email') })),
    language: Yup.string().required(i18n.t('fieldRequired')),
    Person: Yup.object({
      firstName: Yup.string().required(i18n.t('fieldRequired')),
      lastName: Yup.string().required(i18n.t('fieldRequired')),
      organizationId: Yup.number().required(i18n.t('fieldRequired')),
      siteId: Yup.number().required(i18n.t('fieldRequired')),
      visibility: Yup.string().required(i18n.t('fieldRequired')),
    }),
  })
}

/**
 * Admin organization schema.
 *
 * @returns Yup validation object.
 */
export const adminOrganizationSchema = () => {
  return Yup.object({
    companyIdentifier: Yup.string().required(i18n.t('fieldRequired')),
    personnel: Yup.number().moreThan(-1, i18n.t('error.positive')).nullable(),
    turnover: Yup.number().moreThan(-1, i18n.t('error.positive')).nullable(),
    parseCredits: Yup.number(),
    color: Yup.string(),
    accentColor: Yup.string(),
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('fieldRequired')),
        description: Yup.string().nullable(),
      })
    ),
    Sites: Yup.array().min(1).required(i18n.t('fieldRequired')),
    caleoOwner: Yup.boolean(),
    tenantId: Yup.string().nullable(),
    emailDomain: Yup.string().when('tenantId', {
      is: tenantId => tenantId && tenantId.length > 0,
      then: Yup.string().required(i18n.t('fieldRequired')),
      otherwise: Yup.string().nullable(),
    }),
    defaultLanguage: Yup.string().required(i18n.t('fieldRequired')),
    OrganizationExtraFields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().nullable(),
        fieldData: Yup.string().nullable(),
      })
    ),
    allowCreateNetworks: Yup.boolean(),
  })
}

/**
 * Admin site schema.
 *
 * @returns Yup validation object.
 */
export const siteSchema = () => {
  return Yup.object({
    postalCode: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('admin.organization.site.postalCode') })
    ),
    countryCode: Yup.string().required(i18n.t('fieldRequired')),
    isHq: Yup.boolean().required(i18n.t('fieldRequired')),
    streetAddress: Yup.string().required(
      i18n.t('fieldRequiredLabel', { label: i18n.t('admin.organization.site.streetAddress') })
    ),
    city: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('admin.organization.site.city') })),
  })
}

/**
 * Network schema.
 *
 * @returns Yup validation object.
 */
export const networkSchema = () => {
  return Yup.object({
    type: Yup.string().required(i18n.t('fieldRequired')),
    assignmentsVisible: Yup.boolean(),
    salesContactsVisible: Yup.boolean(),
    pricesVisible: Yup.boolean(),
    infoPageVisible: Yup.boolean(),
    parserEnabled: Yup.boolean(),
    chatEnabled: Yup.boolean(),
    unsubNotificationEmail: Yup.string().nullable(),
    assignmentCopyEmail: Yup.string().nullable(),
    NetworkBrokers: Yup.array(),
    NetworkManagers: Yup.array(),
    NetworkMembers: Yup.array(),
    AccountMembers: Yup.array(),
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('network.name') })),
        description: Yup.string().nullable(),
      })
    ),
  })
}

/**
 * Network notifications schema.
 *
 * @param translations - Network translations.
 * @returns Yup validation object.
 */
export const networkNotificationsSchema = (translations: INetworkTranslation[]) => {
  const textFields = {}
  for (const tr of translations) {
    textFields[`header${tr.Language.name}`] = Yup.string().required(i18n.t('fieldRequired'))
    textFields[`message${tr.Language.name}`] = Yup.string().required(i18n.t('fieldRequired'))
  }

  return Yup.object({
    salesOnly: Yup.boolean(),
    skillBased: Yup.boolean(),
    skills: Yup.array().when('skillBased', {
      is: skillBased => skillBased,
      then: Yup.array().min(1).required(i18n.t('fieldRequired')),
      otherwise: Yup.array(),
    }),
    ...textFields,
  })
}

/**
 * Assignment allocation schema.
 *
 * @returns Yup validation object.
 */
export const assignmentAllocationSchema = () => {
  return Yup.object({
    startDate: Yup.string().required(i18n.t('fieldRequired')),
    endDate: Yup.string().nullable().required(i18n.t('fieldRequired')),
    type: Yup.string().required(i18n.t('fieldRequired')),
    assignmentId: Yup.number().when('type', {
      is: type => type && type === 'project',
      then: Yup.number().integer().required(i18n.t('fieldRequired')),
      otherwise: Yup.number().integer().nullable(),
    }),
    roleId: Yup.number().when('assignmentId', {
      is: assignmentId => assignmentId,
      then: Yup.number().integer().required(i18n.t('fieldRequired')),
      otherwise: Yup.number().integer().nullable(),
    }),
    state: Yup.number().integer().required(i18n.t('fieldRequired')),
    percent: Yup.number()
      .required(i18n.t('fieldRequired'))
      .typeError(i18n.t('numberTypeError'))
      .integer()
      .min(1, i18n.t('allocationPercentage.minValue'))
      .max(100, i18n.t('allocationPercentage.maxValue')),
    translations: Yup.array().of(
      Yup.object().shape({
        information: Yup.string().nullable(),
      })
    ),
  })
}

/**
 * Assignment schema.
 *
 * @returns Yup validation object.
 */
export const teamSchema = () => {
  return Yup.object({
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('team.modal.name') })),
        description: Yup.string().nullable(),
      })
    ),
  })
}

/**
 * Assignment schema.
 *
 * @returns Yup validation object.
 */
export const groupSchema = () => {
  return Yup.object({
    name: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('admin.permission.group.name') })),
    description: Yup.string().required(i18n.t('fieldRequired')),
    type: Yup.string().required(i18n.t('fieldRequired')),
    organizationId: Yup.number().when('type', {
      is: type => type !== 'freelancer' && type !== 'recruit' && type !== 'admin' && type !== 'inputDataManager',
      then: Yup.number().required(i18n.t('fieldRequired')),
      otherwise: Yup.number().nullable(),
    }),
  })
}

/**
 * Assignment schema.
 *
 * @returns Yup validation object.
 */
export const assignmentsSchema = () => {
  return Yup.object({
    name: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('assignment.modal.name') })),
    startDate: Yup.date().required(i18n.t('fieldRequired')),
    endDate: Yup.date().nullable(),
    workloadEstimate: Yup.number()
      .nullable()
      .moreThan(-1, i18n.t('error.positive'))
      .test('isOnlyNumber', i18n.t('numberTypeError'), value => {
        if (value || value === 0) {
          if (value.toString().length) {
            return /^\d+$/.test(value.toString())
          } else {
            return value === 0 ? true : false
          }
        } else {
          return false
        }
      }),
    country: Yup.string().required(i18n.t('fieldRequiredLabel', { label: i18n.t('assignment.modal.country') })),
    city: Yup.string(),
    subcontractorAllowed: Yup.boolean().required(i18n.t('fieldRequired')),
    remoteAllowed: Yup.boolean().required(i18n.t('fieldRequired')),
    fileProposalAllowed: Yup.boolean().required(i18n.t('fieldRequired')),
    publicView: Yup.boolean().required(i18n.t('fieldRequired')),
    deadline: Yup.date().required(i18n.t('fieldRequired')),
    description: Yup.string(),
    networks: Yup.array(),
    customer: Yup.string().required(i18n.t('fieldRequired')),
    roles: Yup.array().min(1, i18n.t('fieldRequired')),
    additionalInfo: Yup.string(),
    contactPersonId: Yup.number().when('AssignmentVisibilities', {
      is: AssignmentVisibilities => AssignmentVisibilities.length > 0,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required(i18n.t('fieldRequired')),
    }),
    AssignmentVisibilities: Yup.array().of(
      Yup.object().shape({
        networkId: Yup.number().required(i18n.t('fieldRequired')),
        AssignmentContactPerson: Yup.object({
          personId: Yup.number().required(i18n.t('fieldRequired')),
        }),
      })
    ),
  })
}

/**
 * User manual page schema.
 *
 * @returns Yup validation object.
 */
export const userManualPageSchema = () => {
  return Yup.object({
    url: Yup.string().required(i18n.t('fieldRequired')),
    translations: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(i18n.t('fieldRequired')),
        content: Yup.string().nullable(),
      })
    ),
  })
}

/**
 * CV print layout schema.
 *
 * @returns Yup validation object.
 */
export const cvPrintLayoutSchema = () => {
  return Yup.object({
    name: Yup.string().required(i18n.t('fieldRequired')),
  })
}

/**
 * CV print layout schema.
 *
 * @returns Yup validation object.
 */
export const cvUrlSchema = () => {
  return Yup.object({
    url: Yup.string().required(i18n.t('fieldRequired')),
    translations: Yup.array().of(
      Yup.object().shape({
        description: Yup.string(),
      })
    ),
  })
}

/**
 * CV print layout schema.
 *
 * @returns Yup validation object.
 */
export const assignmentRoleSchema = () => {
  return Yup.object({
    allocationPercentage: Yup.number()
      .required(i18n.t('fieldRequired'))
      .typeError(i18n.t('numberTypeError'))
      .integer()
      .min(1, i18n.t('allocationPercentage.minValue'))
      .max(100, i18n.t('allocationPercentage.maxValue')),
    hourlyPrice: Yup.number().nullable().moreThan(-1, i18n.t('error.positive')),
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('fieldRequired')),
        description: Yup.string().nullable(),
      })
    ),
    RoleQuestionnaire: Yup.object().shape({
      questions: Yup.array().when('.length', {
        is: length => length > 0,
        then: Yup.array()
          .of(
            Yup.object().shape({
              question: Yup.string().required(i18n.t('fieldRequired')).notOneOf(['', '<p><br></p>']),
            })
          )
          .required(i18n.t('fieldRequired')),
        otherwise: Yup.array(),
      }),
    }),
  })
}

/**
 * Copy profile schema.
 *
 * @returns Yup validation object.
 */
export const copyProfileSchema = () => {
  return Yup.object({
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('fieldRequired')),
        description: Yup.string().nullable(),
      })
    ),
  })
}

/**
 * Assignment proposal schema.
 *
 * @returns Yup validation object.
 */
export const assignmentProposalSchema = () => {
  return Yup.object({
    city: Yup.string(),
    remoteAllowed: Yup.boolean(),
    percent: Yup.number()
      .integer()
      .min(1, i18n.t('allocationPercentage.minValue'))
      .max(100, i18n.t('allocationPercentage.maxValue'))
      .nullable(),
    pricePerHour: Yup.number().nullable().moreThan(-1, i18n.t('error.positive')),
    startDate: Yup.string().nullable(),
    duration: Yup.number().nullable().moreThan(-1, i18n.t('error.positive')),
    extensionPossible: Yup.boolean(),
    information: Yup.string(),
  })
}

/**
 * Contact information schema.
 *
 * @returns Yup validation object.
 */
export const contactInformationSchema = () => {
  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3}\)?)|(\(?\d{2,3}\)?))(-| )?(\d{2,4})(-| )?(\d{2,4})(-| )?(\d{2,4})?(( x| ext)\d{1,5}){0,1}/
  return Yup.object({
    telephone: Yup.string()
      .required(i18n.t('fieldRequired'))
      .matches(phoneRegExp, i18n.t('error.telephone.provideInInternationalFormat')),
  })
}

/**
 * Account verification schema.
 *
 * @returns Yup validation object.
 */
export const accountVerificationSchema = () => {
  return Yup.object({
    password: Yup.string().required(i18n.t('password.empty')).min(8, i18n.t('password.tooShort')),
    repeatPassword: Yup.string()
      .required(i18n.t('password.empty'))
      .min(8, i18n.t('password.tooShort'))
      .when('password', {
        is: val => (val && val.length > 7 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], i18n.t('password.notmatch')),
      }),
  })
}

/**
 * Login schema.
 *
 * @returns Yup validation object.
 */
export const loginSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email(i18n.t('emailIsNotValid'))
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('email') })),
    password: Yup.string().min(8, i18n.t('password.tooShort')).required(i18n.t('fieldRequired')),
    remember: Yup.boolean(),
  })
}

/**
 * Register schema.
 *
 * @returns Yup validation object.
 */
export const forgotPasswordSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email(i18n.t('emailIsNotValid'))
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('email') })),
  })
}

/**
 * Register schema.
 *
 * @returns Yup validation object.
 */
export const resetPasswordSchema = () => {
  return Yup.object().shape({
    password: Yup.string().required(i18n.t('password.empty')).min(8, i18n.t('password.tooShort')),
    repeatPassword: Yup.string()
      .required(i18n.t('password.empty'))
      .min(8, i18n.t('password.tooShort'))
      .when('password', {
        is: val => (val && val.length > 7 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], i18n.t('password.notmatch')),
      }),
  })
}

/**
 * Creates a Yup validation schema for changing a password.
 *
 * @return {Yup.ObjectSchema} The validation schema.
 */
export const changePasswordSchema = () => {
  return Yup.object().shape({
    currentPassword: Yup.string().required(i18n.t('fieldRequired')),
    newPassword: Yup.string()
      .required(i18n.t('fieldRequired'))
      .min(8, i18n.t('password.tooShort'))
      .when(['currentPassword'], (currentPassword, schema) =>
        schema.notOneOf([currentPassword], i18n.t('password.matchError'))
      ),
    confirmPassword: Yup.string()
      .required(i18n.t('fieldRequired'))
      .when(['newPassword'], (newPassword, schema) => schema.oneOf([newPassword], i18n.t('password.notmatch'))),
  })
}

/**
 * Proposal question schema.
 */
const proposalQuestionSchema = Yup.object().shape({
  id: Yup.number().required(i18n.t('fieldRequired')),
  question: Yup.string(),
  questionnaireId: Yup.number(),
  answer: Yup.object().shape({
    questionId: Yup.number(),
    result: Yup.boolean().required(i18n.t('fieldRequired')),
  }),
})

/**
 * Proposal allocation schema.
 */
const proposalAllocationSchema = Yup.object().shape({
  state: Yup.number(),
  percent: Yup.number(),
  startDate: Yup.string(),
  endDate: Yup.string().nullable(),
  type: Yup.string(),
  roleId: Yup.string(),
  assignmentId: Yup.string(),
  personId: Yup.string(),
  translations: Yup.array(),
  questionnaireRequired: Yup.boolean(),
  questions: Yup.array().when('questionnaireRequired', {
    is: questionnaireRequired => questionnaireRequired,
    then: Yup.array().of(proposalQuestionSchema).required(i18n.t('fieldRequired')).min(1),
    otherwise: Yup.array(),
  }),
})

/**
 * Proposal attachment schema.
 */
const proposalAttachmentSchema = Yup.object().shape({
  Upload: Yup.object(),
  questionnaireRequired: Yup.boolean(),
  questions: Yup.array().when('questionnaireRequired', {
    is: questionnaireRequired => questionnaireRequired,
    then: Yup.array().of(proposalQuestionSchema).required(i18n.t('fieldRequired')).min(1),
    otherwise: Yup.array(),
  }),
})

/**
 * Proposal schema.
 *
 * @returns Yup validation object.
 */
export const assignmentRoleProposalSchema = () => {
  return Yup.object().shape(
    {
      allocations: Yup.array().when('attachments', {
        is: attachments => !attachments || attachments.length === 0,
        then: Yup.array().of(proposalAllocationSchema).required(i18n.t('fieldRequired')).min(1),
        otherwise: Yup.array().of(proposalAllocationSchema),
      }),
      attachments: Yup.array().when('allocations', {
        is: allocations => !allocations || allocations.length === 0,
        then: Yup.array().of(proposalAttachmentSchema).required(i18n.t('fieldRequired')).min(1),
        otherwise: Yup.array().of(proposalAttachmentSchema),
      }),
      maxPrice: Yup.number().moreThan(-1, i18n.t('error.positive')).nullable(),
      minPrice: Yup.number().moreThan(-1, i18n.t('error.positive')).nullable(),
      additionalInformation: Yup.string(),
    },
    [['allocations', 'attachments']]
  )
}

/**
 * Widget schema.
 *
 * @returns Yup validation object.
 */
export const widgetSchema = () => {
  return Yup.object({
    name: Yup.string().required(i18n.t('fieldRequired')),
    organizationId: Yup.number().required(i18n.t('fieldRequired')),
    leadEmail: Yup.string()
      .email(i18n.t('emailIsNotValid'))
      .required(i18n.t('fieldRequiredLabel', { label: i18n.t('admin.widget.leadEmail') })),
    description: Yup.string().required(i18n.t('fieldRequired')),
    origin: Yup.string().required(i18n.t('fieldRequired')),
  })
}

/**
 * Network register schema.
 *
 * @returns Yup validation object.
 */
export const networkRegisterSchema = () => {
  return Yup.object({
    organization: Yup.boolean().required(i18n.t('fieldRequired')),
    firstName: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string().required(i18n.t('fieldRequired')),
      otherwise: Yup.string(),
    }),
    lastName: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string().required(i18n.t('fieldRequired')),
      otherwise: Yup.string(),
    }),
    email: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string().email(i18n.t('emailIsNotValid')).required(i18n.t('fieldRequired')),
      otherwise: Yup.string(),
    }),
    language: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string().required(i18n.t('fieldRequired')),
      otherwise: Yup.string(),
    }),
    companyIdentifier: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    address: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    city: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    postalCode: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    country: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    defaultLanguage: Yup.string().when('organization', {
      is: organization => organization === false,
      then: Yup.string(),
      otherwise: Yup.string().required(i18n.t('fieldRequired')),
    }),
    users: Yup.array().when('organization', {
      is: organization => organization === false,
      then: Yup.array(),
      otherwise: Yup.array().min(1).required(i18n.t('fieldRequired')).of(networkUserSchema()),
    }),
    translations: Yup.array().when('organization', {
      is: organization => organization === false,
      then: Yup.array(),
      otherwise: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(i18n.t('fieldRequired')),
        })
      ),
    }),
  })
}

/**
 * Network user schema.
 *
 * @returns Yup validation object.
 */
export const networkUserSchema = () => {
  return Yup.object({
    firstName: Yup.string().required(i18n.t('fieldRequired')),
    lastName: Yup.string().required(i18n.t('fieldRequired')),
    email: Yup.string().email(i18n.t('emailIsNotValid')).required(i18n.t('fieldRequired')),
    notifications: Yup.boolean(),
    sales: Yup.boolean(),
    companyAdmin: Yup.boolean(),
    teamLeader: Yup.boolean(),
    language: Yup.string().required(i18n.t('fieldRequired')),
    sendActivation: Yup.boolean(),
  })
}

/**
 * Edit info page schema.
 *
 * @returns Yup validation object.
 */
export const editInfoPageSchema = () => {
  return Yup.object({
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        description: Yup.string(),
        infoPage: Yup.string().required(i18n.t('fieldRequired')),
        proposalGuide: Yup.string(),
      })
    ),
  })
}

/**
 * Edit proposal info schema.
 *
 * @returns Yup validation object.
 */
export const editProposalInfoSchema = () => {
  return Yup.object({
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        description: Yup.string(),
        infoPage: Yup.string(),
        proposalGuide: Yup.string().required(i18n.t('fieldRequired')),
      })
    ),
  })
}

/**
 * Parse setup schema.
 *
 * @returns Yup validation object.
 */
export const parseSetupSchema = () => {
  return Yup.object({
    files: Yup.array().min(1).required(i18n.t('fieldRequired')),
    organizationId: Yup.number().required(i18n.t('fieldRequired')),
    siteId: Yup.number().required(i18n.t('fieldRequired')),
    activation: Yup.boolean().required(i18n.t('fieldRequired')),
    networkVisible: Yup.boolean().required(i18n.t('fieldRequired')),
    parseCredits: Yup.number().moreThan(0).required(i18n.t('fieldRequired')),
  })
}

/**
 * Chat room schema.
 *
 * @returns Yup validation object.
 */
export const chatRoomSchema = () => {
  return Yup.object({
    name: Yup.string().required(i18n.t('fieldRequired')),
    Members: Yup.array().min(1).required(i18n.t('fieldRequired')),
  })
}

/**
 * Admin release note schema.
 *
 * @returns Yup validation object.
 */
export const adminReleseNoteSchema = () => {
  return Yup.object({
    version: Yup.string().required(i18n.t('fieldRequired')),
    translations: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(i18n.t('fieldRequired')),
        note: Yup.string().required(i18n.t('fieldRequired')),
      })
    ),
  })
}
