import { Avatar, Chip, Grid, Tooltip, Typography } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useUser } from 'hooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IError } from 'types/error'
import { ITeam, ITeamMember } from 'types/teamInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import BorderedSection from 'Components/reusable/CaleoCustomComponents/BorderedSection'
import colors from 'constants/colors'

/** @notExported */
interface ITeamCardProps {
  /** The team. */
  item: ITeam
  /** A function to set the edited item. */
  setEditedItem: (team: ITeam) => void
  /** Whether the user is an admin account. */
  adminAccount: boolean
  /** Whether the user has manager access. */
  managerAccess: boolean
  /** A function to set the view item. */
  setViewItem: (team: ITeam) => void
  /** The images. */
  images
}

/**
 * The TeamCard component.
 *
 * @param item - The team.
 * @param setEditedItem - A function to set the edited item.
 * @param adminAccount - Whether the user is an admin account.
 * @param managerAccess - Whether the user has manager access.
 * @param setViewItem - A function to set the view item.
 * @param images - The images.
 * @returns The TeamCard component.
 * @notExported
 */
const TeamCard: React.FC<ITeamCardProps> = ({
  item,
  setEditedItem,
  adminAccount,
  managerAccess,
  setViewItem,
  images,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { user, groups } = useUser()
  const [backendError, setBackendError] = useState<IError>()
  const [isEditable, setIsEditable] = useState(false)

  if (!user) {
    return <LoadingIndicator />
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const isTeamManager = () => {
    if (user) {
      // if user is in item.TeamMembers and manager is true
      if (item.TeamMembers.find(member => member.Account.id === user.id && member.manager)) {
        return true
      }
    }
    return false
  }

  const getChip = (member: ITeamMember, chipColor: 'primary' | 'default', image: string | undefined) => {
    return (
      <Chip
        key={member.id}
        color={chipColor}
        sx={{ mb: 1, ml: 1, borderRadius: 1 }}
        avatar={<Avatar src={image} />}
        label={
          <span>
            {member.Account.Person?.firstName} {member.Account.Person?.lastName}
          </span>
        }
        onClick={e => {
          if (groups.includes('admin') || (groups.includes('companyAdmin') && member.hasAccess) || isTeamManager()) {
            navigate(`/profile/${member.Account.Person?.id}`)
            e.stopPropagation()
          }
        }}
        variant="outlined"
      />
    )
  }

  const translation = chooseDBTranslation(i18n, item.translations)

  const getMembers = (isManager: boolean) =>
    item?.TeamMembers?.filter(member => member.manager === isManager).map((member: ITeamMember) => {
      const image = images.find(img => img.accountId === member.Account.id)?.image
      return groups.includes('admin') || (groups.includes('companyAdmin') && member.hasAccess) ? (
        <Tooltip key={member.id} arrow title={t('team.openProfile') as string}>
          {getChip(member, 'default', image)}
        </Tooltip>
      ) : (
        <React.Fragment key={member.id}>{getChip(member, 'default', image)}</React.Fragment>
      )
    })

  return (
    <Grid
      item
      xs={12}
      md={6}
      xl={4}
      onClick={e => {
        setViewItem(item)
        e.stopPropagation()
      }}
      onMouseEnter={() => setIsEditable(true)}
      onMouseLeave={() => setIsEditable(false)}
      sx={{ cursor: 'pointer' }}
    >
      <BorderedSection color={colors.borderColor} noMargin>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: 45 }}
        >
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              {translation?.name ?? t('team.noName')}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {item?.TeamMembers?.length} {t('team.membersCount')}
          </Grid>
          {(adminAccount || managerAccess) && isEditable && (
            <Grid item xs="auto">
              <EditButton
                clickAction={() => {
                  setEditedItem(item)
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{ color: theme => theme.palette.text.primary }}
          onClick={e => {
            setViewItem(item)
            e.stopPropagation()
          }}
          spacing={1}
        >
          {item.Organization && (
            <Grid item xs={12}>
              <Grid sx={{ fontSize: '16px', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>
                {t('organization')}
              </Grid>
              <Grid item>{chooseDBTranslation(i18n, item.Organization).name}</Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid sx={{ fontSize: '16px', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>
              {t('team.teamLeads')}
            </Grid>
            <Grid>{getMembers(true)}</Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid sx={{ fontSize: '16px', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>
              {t('team.teamMembers')}
            </Grid>
            <Grid>{getMembers(false)}</Grid>
          </Grid>
          {translation?.description && translation?.description.length > 0 && (
            <Grid item xs={12}>
              <Grid sx={{ fontSize: '16px', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>
                {t('team.modal.description')}
              </Grid>
              <Grid sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify', lineHeight: '20px' }}>
                {translation.description}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid sx={{ fontSize: '16px', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }}>
              {t('team.limitAssignmentShort')}
            </Grid>
            <Grid sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify', lineHeight: '20px' }}>
              {item.limitAssignments ? t('yes') : t('no')}
            </Grid>
          </Grid>
        </Grid>
      </BorderedSection>
    </Grid>
  )
}

export default TeamCard
